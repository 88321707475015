import { Box, Typography } from "@mui/material";
import CustomNavTabs from "components/shared/CustomNavTabs";
import styles from "./managementHeader.module.scss";

const ManagementHeader = ({
  selectedTab,
  handleSelectedTab,
  resources,
  cardColors,
  navitems,
  title = "Portal Access Management"
}) => {
  return (
    <Box className={styles["tabs-container"]}>
      <Box className={styles["tabs-section"]}>
        <Typography
          variant="h6"
          component="div"
          className={styles["tabs-header"]}
        >
          {title}

        </Typography>
        <CustomNavTabs
          selectedTab={selectedTab}
          handleSelectedTab={handleSelectedTab}
          navitems={navitems}
        />
      </Box>
      <Box className={styles["resources-section"]}>
        <Typography
          variant="h6"
          component="div"
          className={styles["resources-header"]}
        >
          Total Resources
        </Typography>
        <Box className={styles["resource-cards"]}>
          {resources.map((resource, index) => (
            <Box
              key={resource.label}
              className={`${styles.card} ${styles[resource.className]}`}
              onClick={() => handleSelectedTab(resource.tab)}
            >
              <Box className={styles["card-content"]}>
                <Typography
                  className="count"
                  sx={{ fontWeight: "bold", color: `${cardColors[index]}` }}
                >
                  {resource.count}
                </Typography>
                <Typography className="label">{resource.label}</Typography>
              </Box>
              <Box className={styles["card-icon"]}>{resource.icon}</Box>
              <Box className={styles.curve}></Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ManagementHeader;
