import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
    orgGroups: [],
    selectedGroup: {}
}

const name = 'orgGroups'
const parentSelector = state => state?.[name]

const orgGroupsSlice = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        resetOrgGroups: () => {
            return initialState;
        },
        setOrgGroups: (state, action) => {
            return {
                ...state,
                orgGroups: action.payload,
            };
        },
        setSelectedGroup: (state, action) => {
            return {
                ...state,
                selectedGroup: action.payload,
            };
        },
    },
});


const getOrgGroups = createSelector(parentSelector, state => {
    return state?.orgGroups
});

const getSelectedGroup = createSelector(parentSelector, state => {
    return state?.selectedGroup
})

const actions = {
    ...orgGroupsSlice.actions
}

const selectors = {
    getOrgGroups,
    getSelectedGroup
}

export const orgGroupsActions = { actions, selectors }


export default orgGroupsSlice;