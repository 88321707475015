import { combineReducers } from "@reduxjs/toolkit";
import auth from "./auth.slice";
import applications from './applications.slice';
import networks from './networks.slice';
import packages from './packages.slice';
import ports from './ports.slice';
import processes from './processes.slice';
import system from './system.slice';
import devices from './devices.slice';
import ui from './ui.slice';
import console from './console.slice';
import files from './files.slice';
import fim from './fim.slice';
import policy from './policy.slice';
import group from "./group.slice";
import deviceUsers from "./deviceUsers.slice";
import alerts from "./alerts.slice";
import alertPolicy from "./alertPolicy.slice";
import alertSettingsSlice from "./alertSettings.slice";
import networkDevicesSlice from "./networkDevice.slice";
import analyticsSlice from "./analytics.slice";
import employeesSlice from "./employees.slice";
import orgGroupsSlice from "./orgGroups.slice";
import devicePolicySlice from "./devicePolicy.slice";

const reducers = combineReducers({
  [auth.name]: auth.reducer,
  [applications.name]: applications.reducer,
  [networks.name]: networks.reducer,
  [packages.name]: packages.reducer,
  [ports.name]: ports.reducer,
  [processes.name]: processes.reducer,
  [system.name]: system.reducer,
  [devices.name]: devices.reducer,
  [ui.name]: ui.reducer,
  [console.name]: console.reducer,
  [files.name]: files.reducer,
  [fim.name]: fim.reducer,
  [policy.name]: policy.reducer,
  [group.name]: group.reducer,
  [deviceUsers.name]: deviceUsers.reducer,
  [alerts.name]: alerts.reducer,
  [alertPolicy.name]: alertPolicy.reducer,
  [alertSettingsSlice.name]: alertSettingsSlice.reducer,
  [networkDevicesSlice.name]: networkDevicesSlice.reducer,
  [analyticsSlice.name]: analyticsSlice.reducer,
  [employeesSlice.name]: employeesSlice.reducer,
  [orgGroupsSlice.name]: orgGroupsSlice.reducer,
  [devicePolicySlice.name]: devicePolicySlice.reducer,
});

export default reducers;
