import { Box, Button } from "@mui/material";
import tables from "components/shared/Tables/table.module.scss";
import { ACCESS_MANAGEMENT_TABS } from "components/utils/accessManagement";
import theme from "components/utils/theme";
import DevicesIcon from "static/svgs/Devices";
import PeopleIcon from "static/svgs/People";
import RegisterSettingsIcon from "static/svgs/RegisterSettings";
import UserAppsIcon from "static/svgs/UserApps";
import UsersIcon from "static/svgs/users";


export const getOrdManagmentResources = ({ groups, employees, devices, alertPolicies, policies }) => {
    return [
        {
            count: groups,
            label: "User Group",
            icon: <PeopleIcon />,
            className: "user-group",
            tab: ACCESS_MANAGEMENT_TABS.GROUPS,
        },
        {
            count: employees,
            label: "Employees",
            icon: <UsersIcon />,
            className: "users",
            tab: ACCESS_MANAGEMENT_TABS.USER,
        },
        {
            count: devices,
            label: "Devices",
            icon: <RegisterSettingsIcon />,
            className: "policies",
            tab: ACCESS_MANAGEMENT_TABS.POLICIES,
        },
        {
            count: alertPolicies,
            label: "Alert Policies",
            icon: <UserAppsIcon />,
            className: "roles",
            tab: ACCESS_MANAGEMENT_TABS.USER,
        },
        {
            count: policies,
            label: "Device Policies",
            icon: <DevicesIcon />,
            className: "users",
            tab: ACCESS_MANAGEMENT_TABS.USER,
        }
    ]
}

export const orgNavItems = [
    { label: ACCESS_MANAGEMENT_TABS.GROUPS },
    { label: ACCESS_MANAGEMENT_TABS.EMPLOYEES },
    { label: ACCESS_MANAGEMENT_TABS.DEVICES },
    { label: "Alert Policies" },
    { label: "Device Policies" },
]



export const orgCardcolors = [
    theme.palette.primary.light,
    theme.palette.secondary.main,
    theme.palette.primary.main,
    theme.palette.info.main,
    theme.palette.secondary.main
];

export const employeeColumns = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "phone", headerName: "Phone", flex: 1 },
    { field: "department", headerName: "Department", flex: 1 },
    {
        field: "devices",
        headerName: "Devices",
        flex: 2,
        renderCell: (params) => (
            <Box sx={{ maxWidth: "100%", overflowX: "auto", display: "flex" }}>
                <Box sx={{ minWidth: "fit-content" }}>
                    {params.row.devices.map((dev, index) => (
                        <Button
                            key={index}
                            variant="outlined"
                            className={tables.actionBtn}
                            size="small"
                            sx={{ marginRight: 1, whiteSpace: "nowrap" }}
                        >
                            {dev}
                        </Button>
                    ))}
                </Box>
            </Box>
        ),
    },
];

