import OrgManagement from 'components/modules/Management/OrganizationManagement/OrgManagement';

const OrganizationManagement = () => {
    return (
        <>
            <OrgManagement />
        </>
    )
}

export default OrganizationManagement
