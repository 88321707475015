import { TOPICS } from "components/utils/enums";
import {
  countUserRoles
} from "components/utils/policy";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setupMultiAppsSocket,
  unSubscribeSocketTopics,
} from "../../../../redux/saga/socket";
import { getUsersService } from "../../../../redux/services/group.service";
import { applicationsActions } from "../../../../redux/slice/applications.slice";
import { authActions } from "../../../../redux/slice/auth.slice";
import { packagesActions } from "../../../../redux/slice/packages.slice";
import { FETCH_ALERT_POLICIES } from "../../../../redux/types/alertPolicy.types";
import { FETCH_GROUPS } from "../../../../redux/types/group.types";
import { GET_MULTI_DASHBOARD_ANALYTICS } from "../../../../redux/types/multi.types";
import { FETCH_POLICIES } from "../../../../redux/types/policy.types";
import ManagementHeader from "../ManagementHeader/ManagementHeader";
import Groups from "./Groups/Groups";
import { getPortalManagmentResourses, portalCardcolors, portalNavItems } from "./helper";
import Policies from "./Policies/Policies";
import Users from "./Users/Users";

const PortalManagement = () => {
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [selectedTab, handleSelectedTab] = useState(0);
  const groups = useSelector((state) => state.group.groups);
  const policies = useSelector((state) => state.policy.policies);
  const currentUser = useSelector(authActions.selectors.getCurrentUser);

  useEffect(() => {
    dispatch({ type: FETCH_GROUPS });
    dispatch({ type: FETCH_POLICIES });
    dispatch({ type: FETCH_ALERT_POLICIES });

    const fetchUsers = async () => {
      try {
        const users = await getUsersService();
        setUsers(users);
      } catch (error) {
        console.error("Failed to fetch users", error);
      }
    };

    fetchUsers();
  }, [dispatch]);

  const refreshGroups = () => {
    dispatch({ type: FETCH_GROUPS });
  };

  useEffect(() => {
    dispatch({ type: GET_MULTI_DASHBOARD_ANALYTICS });
    setupMultiAppsSocket(dispatch);
    return () => {
      unSubscribeSocketTopics([TOPICS.SYSTEM_PACKAGES, TOPICS.APPLICATIONS]);
    };
  }, [dispatch]);

  const applications = useSelector(applicationsActions.selectors.getMultiApps);
  const packages = useSelector(packagesActions.selectors.getMultiPackages);

  const combineApps = [...applications, ...packages];

  const renderContent = (selectedTab) => {
    switch (selectedTab) {
      case 0:
        return <Groups refreshGroups={refreshGroups} />;
      case 1:
        return (
          <Users
            user={users}
            groups={groups}
            refreshGroups={refreshGroups}
            currentUser={currentUser}
          />
        );
      case 2:
        return (
          <>
            <Policies
              policies={policies}
              groups={groups}
              refreshGroups={refreshGroups}
            />
          </>
        );
      default:
        return <Groups refreshGroups={refreshGroups} />;
    }
  };

  const resources = useMemo(() => {
    return getPortalManagmentResourses({ groups, users, policies });

  }, [groups, users, policies])



  return (
    <div>
      <ManagementHeader
        selectedTab={selectedTab}
        handleSelectedTab={handleSelectedTab}
        userCount={users.length}
        roleCount={countUserRoles(users).length}
        policyCount={policies?.length}
        appsCount={combineApps?.length}
        resources={resources}
        cardColors={portalCardcolors}
        navitems={portalNavItems}
      />
      {renderContent(selectedTab)}
    </div>
  );
};

export default PortalManagement;
