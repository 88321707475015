import { useTheme } from "@emotion/react";
import { IconButton, MenuItem, Select } from "@mui/material";
import { memo } from "react";
import DeleteIcon from "../../../../static/svgs/Delete";
import DownloadIcon from "../../../../static/svgs/Download";
import PrintIcon from "../../../../static/svgs/Print";
import CustomButton from "../../Buttons/CustomButton";
import SearchTextField from "../../Forms/SearchTextField";
import tables from "../table.module.scss";
import styles from "./myTable.module.scss";

const MyTableToolbar = ({
  placeHolder,
  btn1Txt,
  btn1Icon,
  btn2Txt,
  btn2Icon,
  handleBtn1Click,
  handleBtn2Click,
  handleDeleteClick,
  onSearch,
  onDownload,
  onPrint,
  isUninstall,
  isAlerts,
  selectMenuOptions,
  selectedOption,
  handleSelect
}) => {
  const theme = useTheme();
  return (
    <div className={styles.toolBarContainer}>
      <div className={styles.left}>
        <SearchTextField
          variant={"outlined"}
          placeholder={placeHolder}
          onSearch={onSearch}
        />
      </div>
      <div className={styles.right}>
        <div>
          {isAlerts &&
            <Select
              value={selectedOption}
              onChange={handleSelect}
              size='small'
              className={styles.selectInput}
              sx={{
                height: '30px',
                borderRadius: '8px',
                color: theme.palette.gray.main
              }}
            >
              {selectMenuOptions?.map((item, idx) => (<MenuItem key={idx} value={item?.value}>{item?.label}</MenuItem>))}
            </Select>
          }
          <IconButton className={tables.iconBtn} onClick={onDownload}>
            <DownloadIcon />
          </IconButton>
          <IconButton className={tables.iconBtn} onClick={onPrint}>
            <PrintIcon />
          </IconButton>
          {isUninstall ? (
            <IconButton onClick={handleDeleteClick} className={tables.iconBtn}>
              <DeleteIcon fill={"#030229"} width="22" height="22" />
            </IconButton>
          ) : null}
        </div>
        <div>
          {btn1Txt && (
            <CustomButton
              onClick={handleBtn1Click}
              label={btn1Txt}
              Icon={btn1Icon}
              bgColor={theme.palette.primary.light}
              color={theme.palette.white.main}
              hover={theme.palette.info.main}
            />
          )}
          {btn2Txt && (
            <CustomButton
              onClick={handleBtn2Click}
              label={btn2Txt}
              Icon={btn2Icon}
              bgColor={theme.palette.primary.main}
              color={theme.palette.white.main}
              hover={theme.palette.info.main}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(MyTableToolbar);
