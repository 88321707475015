import { DialogActions, DialogContent, FormControl, MenuItem, Select, Typography } from '@mui/material'
import CustomButton from 'components/shared/Buttons/CustomButton'
import theme from 'components/utils/theme'
import React from 'react'

const OrgGroupSelector = ({ groups, selectedGroup, setSelectedGroup, handleClose, handleAddToGroup }) => {
    return (
        <>
            <DialogContent
                sx={{
                    p: 0,
                    width: "40vw",
                }}
            >
                <FormControl fullWidth variant="outlined" margin="normal">
                    <Typography variant="subtitle1" fontWeight="500">
                        Group
                    </Typography>
                    <Select
                        value={selectedGroup || groups?.[0]?.name}
                        onChange={(e) => setSelectedGroup(e.target.value)}
                        sx={{ height: 40, borderRadius: 2 }}
                    >
                        {groups.map((group) => (
                            <MenuItem key={group._id} value={group._id}>
                                {group?.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DialogContent>

            <DialogActions
                sx={{
                    p: 0,
                    paddingBottom: "40px",
                    paddingTop: "40px",
                }}
            >
                <CustomButton
                    padding={"0 50px"}
                    color={theme.palette.primary.main}
                    bgColor={theme.palette.background.default}
                    hover={theme.palette.gray.light}
                    onClick={handleClose}
                    label={"Cancel"}
                />
                <CustomButton
                    padding={"0 50px"}
                    bgColor={theme.palette.primary.main}
                    hover={theme.palette.primary.light}
                    label="Add"
                    onClick={handleAddToGroup}
                    disabled={!selectedGroup}
                />
            </DialogActions>
        </>
    )
}

export default OrgGroupSelector
