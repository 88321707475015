import apiInterceptor from "./Interceptors/ApiInterceptor";

export const getNetworkDeviceTokenServ = async (payload) => {
  try {
    const response = await apiInterceptor.post(
      `/devdisc/get-device-network-token`,
      payload
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};


export const getNetworkDevicesServ = async (payload) => {
  try {
    const response = await apiInterceptor.get(`/devdisc/get-device-network`, {
      params: payload,
    });
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const deleteNetworkDevicesServ = async (payload) => {
  try {
    const response = await apiInterceptor.delete(`/devdisc/delete-multiple-networks`, {
      data: payload
    });
    return response?.data;
  } catch (error) {
    throw error;
  }
}



export const getSingleNetworkDevicesServ = async (payload) => {
  try {
    const response = await apiInterceptor.get(`/devdisc/get-single-device-network`, {
      params: payload
    });
    return response?.data;
  } catch (error) {
    throw error;
  }
}

export const getAllNetworkLocationsServ = async (payload) => {
  try {
    const response = await apiInterceptor.get(`/devdisc/get-all-network-locations`, {
      params: payload
    });
    return response?.data;
  } catch (error) {
    throw error;
  }
}

export const getSingleNetworkLocationServ = async (payload) => {
  try {
    const response = await apiInterceptor.get(`/devdisc/get-single-network-location`, {
      params: payload
    });
    return response?.data;
  } catch (error) {
    throw error;
  }
}

export const saveNetworkCredentialsServ = async (payload) => {
  try {
    const response = await apiInterceptor.put(`/devdisc/save-network-credentials`, payload);
    return response?.data;
  } catch (error) {
    throw error;
  }
};