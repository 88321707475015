import { Box } from '@mui/material';
import * as d3 from 'd3';
import React, { useEffect, useRef } from 'react';
import styles from '../SingleDashboard/singleDashboard.module.scss';

const PieChart = ({
    chartData,
    width,
    height,
    graphColors,
    totalValue,
    totalText,
    radius,
    left,
    showLabels,
    top = "'95px'",
    showPercentage = true,
    handleClick
}) => {
    const ref = useRef();

    useEffect(() => {
        const svgElement = d3.select(ref.current);

        // Clear any previous SVG elements before appending new ones
        svgElement.selectAll("*").remove();

        const color = d3.scaleOrdinal()
            .domain(Object.keys(chartData))
            .range(graphColors);

        // append the svg object to the div
        const svg = svgElement
            .attr("width", width)
            .attr("height", height)
            .append("g")
            .attr("transform", `translate(${width / 2},${height / 2})`);

        const pie = d3.pie()
            .sort(null) // Do not sort group by size
            .value(d => d[1]);
        const data_ready = pie(Object.entries(chartData));

        // The arc generator
        const arc = d3.arc()
            .innerRadius(radius * 0.55) // Increase the size of the donut hole
            .outerRadius(radius * 0.8); // Decrease the outer radius

        // Another arc that won't be drawn. Just for labels positioning
        const outerArc = d3.arc()
            .innerRadius(radius * 0.9)
            .outerRadius(radius * 0.9);

        // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
        svg
            .selectAll('allSlices')
            .data(data_ready)
            .enter()
            .append('path')
            .attr('d', arc)
            .attr('fill', d => color(d.data[0]))
            .attr("stroke", "white")
            .style("stroke-width", "2px")
            .style("opacity", 0.7)
            .style("cursor", "pointer")
            .on('mouseover', function () {
                d3.select(this)
                    .transition()
                    .duration(200)
                    .style("opacity", 1)
                    .attr('transform', 'scale(1.05)'); // Slightly scale up on hover
            })
            .on('mouseout', function () {
                d3.select(this)
                    .transition()
                    .duration(200)
                    .style("opacity", 0.7)
                    .attr('transform', 'scale(1)'); // Reset scale on mouse out
            })
            .on('click', (event, d) => {
                // Pass the slice data (key, value) to the handleClick function
                handleClick(d.data[0], d.data[1]);
            });

        if (showLabels) {
            // Add the labels with values
            svg
                .selectAll('allLabels')
                .data(data_ready)
                .enter()
                .append('text')
                .text(d => `${d.data[1]}${showPercentage ? "%" : ""}`)
                .attr('transform', function (d) {
                    const pos = outerArc.centroid(d);
                    const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
                    pos[0] = radius * 0.95 * (midangle < Math.PI ? 1 : -1); // Adjusted position to reduce space
                    return `translate(${pos})`;
                })
                .style('text-anchor', function (d) {
                    const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
                    return (midangle < Math.PI ? 'start' : 'end');
                })
                .style('fill', d => color(d.data[0]));
        }
    }, [chartData, graphColors, height, radius, width, showLabels, handleClick]);

    return (
        <div className={styles.chartContainer} style={{ width: width, height: height }}>
            <svg ref={ref}></svg>
            <Box className={styles.chartBadge} sx={{ left: left, top: top }}>
                <p>{totalText}</p>
                <p className={styles.badge}>{totalValue}</p>
            </Box>
        </div>
    );
};

export default PieChart;
