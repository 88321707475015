import { format, isValid } from "date-fns";
import { dashboardNavItems } from ".";

export const generateCsv = (rows, columns) => {
  const headers = columns.map((col) => col.headerName).join(",");
  const dataRows = rows.map((row) =>
    columns.map((col) => row[col.field]).join(",")
  );
  return [headers, ...dataRows].join("\n");
};

export const generateCsvDevices = (rows, columns) => {
  const headers = columns.map((col) => col.headerName).join(",");
  const dataRows = rows.map((row) => {
    return columns
      .map((col) => {
        if (col.field === "createdAt" || col.field === "lastActive") {
          const date = new Date(row[col.field]);
          return isValid(date)
            ? format(date, "d MMMM, yyyy h:mmaaa")
            : "Invalid date";
        }
        if (col.valueGetter) {
          return col.valueGetter({ row });
        }
        return row[col.field];
      })
      .join(",");
  });
  return [headers, ...dataRows].join("\n");
};

export const generateCsvGroups = (rows, columns) => {
  const headers = columns.map((col) => col.headerName).join(",");
  const dataRows = rows.map((row) => {
    return columns
      .map((col) => {
        if (col.field === "users") {
          const users = row[col.field];
          return users?.length;
        }
        if (col.field === "devices") {
          const users = row[col.field];
          return users?.length;
        }
        if (col.field === "policies") {
          const users = row[col.field];
          return users?.length;
        }
        if (col.field === "createdAt") {
          const date = new Date(row[col.field]);
          return isValid(date)
            ? format(date, "d MMMM, yyyy h:mmaaa")
            : "Invalid date";
        }
        if (col.valueGetter) {
          return col.valueGetter({ row });
        }
        return row[col.field];
      })
      .join(",");
  });
  return [headers, ...dataRows].join("\n");
};

export const generateHtmlTable = (csvContent) => {
  const rows = csvContent.split("\n");
  const headers = rows[0].split(",");
  const dataRows = rows.slice(1);
  return `
      <table>
        <thead>
          <tr>${headers.map((header) => `<th>${header}</th>`).join("")}</tr>
        </thead>
        <tbody>
          ${dataRows
      .map(
        (row) =>
          `<tr>${row
            .split(",")
            .map((cell) => `<td>${cell}</td>`)
            .join("")}</tr>`
      )
      .join("")}
        </tbody>
      </table>
    `;
};

export const checkUserDevicePermissions = (userId, groups, role) => {
  const permissionsResult = {
    ADD_DEVICE: false,
    DELETE_DEVICE: false,
    VIEW_DEVICE: false,
  };

  const grantAllPermissions = () => {
    for (const permission in permissionsResult) {
      permissionsResult[permission] = true;
    }
  };


  if (role === "admin") {
    grantAllPermissions();
    return permissionsResult;
  }

  for (const groupType in groups) {
    groups[groupType].forEach((group) => {
      const userInGroup = group.users.find((user) => user._id === userId);

      if (userInGroup) {
        group.policies.forEach((policy) => {
          policy.permissions.forEach((permission) => {
            if (permissionsResult.hasOwnProperty(permission)) {
              permissionsResult[permission] = true;
            }
          });
        });
      }
    });
  }

  return permissionsResult;
};

export const checkUserDeviceUserPermissions = (userId, deviceId, groups, role) => {
  const permissionsResult = {
    ADD_DEVICE_USER: false,
    DELETE_DEVICE_USER: false,
    VIEW_DEVICE_USER: false,
  };

  const grantAllPermissions = () => {
    for (const permission in permissionsResult) {
      permissionsResult[permission] = true;
    }
  };

  if (role === "admin") {
    grantAllPermissions();
    return permissionsResult;
  }

  for (const groupType in groups) {
    groups[groupType].forEach((group) => {
      const userInGroup = group.users.find((user) => user._id === userId);

      const isDeviceInGroup = group.devices.some(
        (device) => device._id === deviceId
      );

      if (userInGroup && isDeviceInGroup) {
        group.policies.forEach((policy) => {
          policy.permissions.forEach((permission) => {
            if (permissionsResult.hasOwnProperty(permission)) {
              permissionsResult[permission] = true;
            }
          });
        });
      }
    });
  }

  return permissionsResult;
};

export const permissionsList = [
  "ADD_DEVICE",
  "DELETE_DEVICE",
  "VIEW_DEVICE",
  "ADD_DEVICE_USER",
  "DELETE_DEVICE_USER",
  "VIEW_DEVICE_USER",
  "BLUTOOTH_PORT",
  "USB_PORT",
  "VGA_PORT",
];

export const filteredNavItems = dashboardNavItems.filter(
  (item) => item.label !== "Users"
);

export const ACCESS_MANAGEMENT_TABS = {
  GROUPS: "Groups",
  DEPARTMENTS: "Departments",
  USER: "Users",
  EMPLOYEES: "Employees",
  POLICIES: "Policies",
  ROLES: "Roles",
  DEVICES: "Devices",
  APPS: "Apps",
};

export const alertOptions = {
  windows: {
    FIM: [
      "C:\\Windows",
      "C:\\Windows\\System32",
      "C:\\Windows\\SysWOW64",
      "C:\\bootmgr",
      "C:\\Boot\\BCD",
      "C:\\Program Files",
      "C:\\Program Files (x86)",
      "C:\\Users\\<UserName>\\AppData",
      "C:\\Users\\<UserName>\\Documents",
      "C:\\Users\\<UserName>\\Desktop",
      "C:\\Users\\<UserName>\\Downloads",
      "C:\\Windows\\System32\\drivers",
      "C:\\Windows\\System32\\Config",
      "C:\\Windows\\System32\\winevt\\Logs",
      "C:\\Windows\\Temp",
      "C:\\Windows\\System32\\config\\system",
      "C:\\Windows\\System32\\config\\software",
      "C:\\Windows\\explorer.exe",
      "C:\\Windows\\notepad.exe",
      "C:\\Windows\\regedit.exe",
    ],
    Port: [
      80, 443, 22, 53, 21, 20, 25, 465, 587, 110, 995, 143, 993, 3306, 5432,
      1521, 1433, 3389, 137, 138, 139, 445, 389, 636,
    ],
    Process: [
      "System",
      "smss.exe",
      "csrss.exe",
      "wininit.exe",
      "services.exe",
      "lsass.exe",
      "svchost.exe",
      "explorer.exe",
      "spoolsv.exe",
      "winlogon.exe",
      "dwm.exe",
      "taskhostw.exe",
      "audiodg.exe",
      "msmpeng.exe",
      "mstsc.exe",
      "msiexec.exe"
    ]
  },
  ubuntu: {
    FIM: [
      "/etc",
      "/etc/passwd",
      "/etc/shadow",
      "/etc/group",
      "/etc/gshadow",
      "/etc/fstab",
      "/boot",
      "/bin",
      "/sbin",
      "/lib",
      "/lib64",
      "/usr/bin",
      "/usr/sbin",
      "/usr/lib",
      "/usr/lib64",
      "/var/log",
      "/var/spool",
      "/var/tmp",
      "/var/www",
      "/root",
      "/home",
      "/tmp",
      "/srv",
      "/opt",
    ],
    Port: [
      80, 443, 22, 53, 21, 20, 25, 465, 587, 110, 995, 143, 993, 3306, 5432,
      1521, 1433, 2049, 514,
    ],
    Process: [
      "init",
      "systemd",
      "sshd",
      "cron",
      "dbus-daemon",
      "NetworkManager",
      "apache2",
      "nginx",
      "mysql",
      "postgres",
      "dhclient",
      "bash",
      "rsyslogd",
      "dockerd",
      "udevd",
      "cupsd",
      "Xorg"
    ]
  },
};

export const permissionsTreeData = [
  {
    label: "Devices",
    value: "devices",
    children: [
      { label: "ADD_DEVICE", value: "ADD_DEVICE" },
      { label: "DELETE_DEVICE", value: "DELETE_DEVICE" },
      { label: "VIEW_DEVICE", value: "VIEW_DEVICE" },
    ],
  },
  {
    label: "User",
    value: "user",
    children: [
      { label: "ADD_DEVICE_USER", value: "ADD_DEVICE_USER" },
      { label: "DELETE_DEVICE_USER", value: "DELETE_DEVICE_USER" },
      { label: "VIEW_DEVICE_USER", value: "VIEW_DEVICE_USER" },
    ],
  },
  {
    label: "Port",
    value: "port",
    children: [
      { label: "BLUETOOTH_PORT", value: "BLUETOOTH_PORT" },
      { label: "USB_PORT", value: "USB_PORT" },
      { label: "VGA_PORT", value: "VGA_PORT" },
    ],
  },
];

export const getAllDescendants = (node) => {
  let descendants = [node.value];
  if (node.children && node.children.length > 0) {
    node.children.forEach((child) => {
      descendants = descendants.concat(getAllDescendants(child));
    });
  }
  return descendants;
};
