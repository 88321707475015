import PortalManagement from "components/modules/Management/AccessManagement/PortalManagement";

const AccessManagement = () => {
  return (
    <>
      <PortalManagement />
    </>
  );
};

export default AccessManagement;
