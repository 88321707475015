import { Add, Delete, Edit, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CustomButton from "components/shared/Buttons/CustomButton";
import CustomDialog from "components/shared/Dialog/CustomDialog";
import { handleDownloadCsv, handlePrintCsv } from "components/utils/handlers";
import theme from "components/utils/theme";
import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import BarsIcon from "static/svgs/Bars";
import DownloadIcon from "static/svgs/Download";
import PrintIcon from "static/svgs/Print";
import {
  ASSIGN_POLICY_TO_GROUP,
  CREATE_POLICY,
  DELETE_POLICY,
  FETCH_POLICIES,
  UPDATE_POLICY,
} from "../../../../../redux/types/policy.types";
import { customTableStyles } from "../../../../shared/Tables/helper";
import styles from "./Policies.module.scss";
import PolicyChecker from "./PolicyChecker";

const Policies = ({ policies, groups, refreshGroups }) => {
  const [pageSize, setPageSize] = useState(10);
  const [selectedPolicies, setSelectedPolicies] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [assignOpen, setAssignOpen] = useState(false);
  const [createOpen, setCreateOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [newPolicy, setNewPolicy] = useState();
  const [currentPolicy, setCurrentPolicy] = useState(null);
  const [policyToDelete, setPolicyToDelete] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: FETCH_POLICIES });
  }, [dispatch]);

  const handleAssignOpen = () => {
    if (selectedPolicies.length === 0) {
      toast.error("Please choose policy first");
      return;
    }
    setAssignOpen(true);
  };

  const handleAssignClose = () => {
    setAssignOpen(false);
    setSelectedGroup("");
    setSelectedPolicies([]);
  };

  const handleCreateOpen = () => {
    setCreateOpen(true);
  };

  const handleCreateClose = () => {
    setCreateOpen(false);
    setNewPolicy("");
    setSelectedPermissions([]);
  };

  const handleUpdateOpen = (policy) => {
    setCurrentPolicy(policy);
    setNewPolicy(policy?.name);
    setSelectedPermissions(policy?.permissions);
    setUpdateOpen(true);
  };

  const handleUpdateClose = () => {
    setUpdateOpen(false);
    setCurrentPolicy(null);
    setSelectedPermissions([]);
    setNewPolicy("");
  };

  const handleDeleteOpen = (policyId) => {
    setPolicyToDelete(policyId);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setPolicyToDelete(null);
  };

  const handleAssignPolicyToGroup = async () => {
    dispatch({
      type: ASSIGN_POLICY_TO_GROUP,
      payload: { groupId: selectedGroup, policyIds: selectedPolicies },
    });
    handleAssignClose();
  };

  const handleCreatePolicy = () => {
    if (!newPolicy?.trim()) {
      toast.error("Please fill in role name fields.");
      return;
    }

    const payload = {
      name: newPolicy,
      permissions: selectedPermissions,
    };
    dispatch({ type: CREATE_POLICY, payload });
    handleCreateClose();
    refreshGroups();
  };

  const handleUpdatePolicy = () => {
    if (!newPolicy.trim()) {
      toast.error("Please fill in policy name fields.");
      return;
    }
    dispatch({
      type: UPDATE_POLICY,
      payload: {
        id: currentPolicy?.id,
        name: newPolicy,
        permissions: selectedPermissions,
      },
    });
    handleUpdateClose();
    refreshGroups();
  };

  const handleDeletePolicy = () => {
    dispatch({ type: DELETE_POLICY, payload: policyToDelete });
    handleDeleteClose();
    refreshGroups();
  };

  const handleInputChange = (e) => {
    setNewPolicy(e.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredPolicies = policies.filter(
    (policy) =>
      policy?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
      policy?.permissions
        .join(", ")
        ?.toLowerCase()
        .includes(searchQuery?.toLowerCase())
  );

  const columns = [
    { field: "name", headerName: "Policy Name", flex: 1 },
    {
      field: "permissions",
      headerName: "Permissions",
      flex: 4,
      renderCell: (params) => params.value.join(", "),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <>
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              handleUpdateOpen(params.row);
            }}
          >
            <Edit />
          </IconButton>
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              handleDeleteOpen(params.row.id);
            }}
          >
            <Delete />
          </IconButton>
        </>
      ),
    },
  ];

  const rows = useMemo(() => {
    return filteredPolicies?.map((policy) => ({
      id: policy._id,
      name: policy.name,
      permissions: policy.permissions,
    }));
  }, [filteredPolicies]);

  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const handlePermissionSelection = (selectedItems) => {
    setSelectedPermissions(selectedItems);
  };

  return (
    <Box className={styles.container}>
      <Box className={styles.headerContainer}>
        <Typography variant="h6" className={styles.header}>
          Roles
        </Typography>
        <Typography className={styles.subheader}>
          A Role is an Object in Bdata that Defines Permissions.
        </Typography>
        <Box className={styles.controls}>
          <Box className={styles.iconButtons}>
            <IconButton className={styles.iconBtn} onClick={handleDownloadCsv}>
              <DownloadIcon />
            </IconButton>
            <IconButton className={styles.iconBtn} onClick={handlePrintCsv}>
              <PrintIcon />
            </IconButton>
            <IconButton className={styles.iconBtn}>
              <BarsIcon />
            </IconButton>
          </Box>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={styles.createButton}
            onClick={handleAssignOpen}
          >
            Assign Roles to Group
            <Add className={styles.icon} />
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={styles.createButton}
            onClick={handleCreateOpen}
          >
            Add New Role
            <Add className={styles.icon} />
          </Button>
        </Box>
      </Box>
      <Box className={styles.tableControls}>
        <TextField
          variant="outlined"
          placeholder="Search for policies"
          size="small"
          value={searchQuery}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <IconButton size="small">
                <Search />
              </IconButton>
            ),
          }}
          className={styles.search}
        />
      </Box>
      <Box sx={{ width: "100%", height: "450px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 30]}
          pagination
          sx={customTableStyles}
          checkboxSelection
          onRowSelectionModelChange={(newSelectionModel) => {
            setSelectedPolicies(newSelectionModel);
          }}
          rowSelectionModel={selectedPolicies}
          disableColumnMenu
        />
      </Box>
      <CustomDialog
        title="Select Group"
        isOpen={assignOpen}
        handleToggle={handleAssignClose}
        submitBottomTitle="Invite"
        fullWidth
        noContent
      >
        <DialogContent
          sx={{
            p: 0,
            paddingLeft: "48px",
            paddingRight: "48px",
          }}
        >
          <FormControl fullWidth variant="outlined" margin="normal">
            <Typography variant="subtitle1" fontWeight="500">
              Group
            </Typography>
            <Select
              value={selectedGroup}
              onChange={(e) => setSelectedGroup(e.target.value)}
              sx={{ height: 40, borderRadius: 2 }}
            >
              {Object.keys(groups).map((groupType) =>
                groups[groupType].map((group) => (
                  <MenuItem key={group._id} value={group._id}>
                    {group.name}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        </DialogContent>

        <DialogActions
          sx={{
            p: 0,
            paddingRight: "48px",
            paddingBottom: "40px",
            paddingTop: "40px",
          }}
        >
          <CustomButton
            padding={"0 50px"}
            color={theme.palette.primary.main}
            bgColor={theme.palette.background.default}
            hover={theme.palette.gray.light}
            onClick={handleAssignClose}
            label={"Cancel"}
          />
          <CustomButton
            padding={"0 50px"}
            bgColor={theme.palette.primary.main}
            hover={theme.palette.primary.light}
            label="Add"
            onClick={handleAssignPolicyToGroup}
            disabled={!selectedGroup}
          />
        </DialogActions>
      </CustomDialog>

      {/* Create New Policy Dialog */}
      <CustomDialog
        title={"Add a New Policy"}
        isOpen={createOpen}
        handleToggle={handleCreateClose}
        maxWidth={"lg"}
        action
        submitBottomTitle="Create"
        handleSubmit={handleCreatePolicy}
      >
        <Box width={600} display={"flex"} flexDirection={"column"} gap={1}>
          <Box>
            <Typography variant="subtitle1" fontWeight="500">
              Policy name
            </Typography>
            <TextField
              name="name"
              placeholder="Policy Name"
              variant="outlined"
              fullWidth
              margin="normal"
              value={newPolicy}
              onChange={handleInputChange}
              sx={{ marginTop: 0.8 }}
            />
          </Box>
          <PolicyChecker
            selectedPermissions={selectedPermissions}
            handlePermissionSelection={handlePermissionSelection}
          />
        </Box>
      </CustomDialog>

      {/* Update Policy Dialog */}
      <CustomDialog
        title={"Update Policy"}
        isOpen={updateOpen}
        handleToggle={handleUpdateClose}
        maxWidth={"lg"}
      >
        <Box width={600} display={"flex"} flexDirection={"column"} gap={1}>
          <TextField
            name="name"
            label="Policy Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={newPolicy}
            onChange={handleInputChange}
          />
          {console.log("selectedPermissions", selectedPermissions)}
          <PolicyChecker
            selectedPermissions={selectedPermissions}
            handlePermissionSelection={handlePermissionSelection}
          />
          <DialogActions>
            <Button onClick={handleUpdateClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleUpdatePolicy} color="primary">
              Update
            </Button>
          </DialogActions>
        </Box>
      </CustomDialog>

      {/* Delete Policy Confirmation Dialog */}
      <Dialog
        open={deleteOpen}
        onClose={handleDeleteClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Delete Policy</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this role?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeletePolicy} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Policies;
