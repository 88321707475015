import toast from "react-hot-toast";
import { put, select, takeEvery } from "redux-saga/effects";
import { SOMETHING_WENT_WRONG } from "../../static/constants";

import { employeesActions } from "../slice/employees.slice";
import { CREATE_EMPLOYEE, DELETE_EMPLOYEES, GET_EMPLOYEES } from "../types/employee.types";

import { createEmployeeServ, deleteEmployeeServ, getEmployeeServ } from "../services/employee.service";

export function* getEmployees(action) {
  try {
    const employees = yield getEmployeeServ(action.payload);
    if (employees) {
      yield put(employeesActions.actions.setEmployees(employees?.data));
    } else {
      // toast.error("Failed to load Employees");
    }
  } catch (err) {
    console.log("err", err);
    toast.error(SOMETHING_WENT_WRONG);
  }
}

export function* createEmployee(action) {
  try {
    const employee = yield createEmployeeServ(action.payload);
    if (employee) {
      const prevEmployees = yield select(employeesActions.selectors.getEmployees)
      yield put(employeesActions.actions.setEmployees([employee?.data, ...prevEmployees]));
      toast.success("Employee Created Successfully");
    } else {
      toast.error("Failed to load Employees");
    }
  } catch (err) {
    console.log("err", err);
    toast.error(SOMETHING_WENT_WRONG);
  }
}




export function* deleteEmployees(action) {
  try {
    const response = yield deleteEmployeeServ(action.payload);
    if (response) {
      const employees = yield select(employeesActions.selectors.getEmployees);
      const updatedEmployees = employees.filter(
        (emp) => !response?.data.ids.includes(emp._id)
      );
      yield put(employeesActions.actions.setEmployees(updatedEmployees));
      toast.success(
        `${response?.data.ids?.length} Employee${response?.data.ids?.length >= 1 ? "" : "s"
        } Deleted Successfully`
      );
    }
  } catch (err) {
    console.log("err", err);
    toast.error(SOMETHING_WENT_WRONG);
  }
}



export function* watchEmployeeAsync() {
  yield takeEvery(GET_EMPLOYEES, getEmployees);
  yield takeEvery(CREATE_EMPLOYEE, createEmployee);
  yield takeEvery(DELETE_EMPLOYEES, deleteEmployees);
}
