import React from "react";

const CloseIcon = ({ fill = "#FFF", width = "22", height = "22" }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.352 5.99355L10.7157 2.62982C11.0932 2.25433 11.0932 1.64612 10.7196 1.26868C10.3442 0.891238 9.73594 0.891238 9.3585 1.26477L9.35654 1.26672L5.99281 4.63045L2.62713 1.26868C2.25164 0.893193 1.64148 0.893193 1.26599 1.26868C0.890503 1.64417 0.890503 2.25433 1.26599 2.62982L4.62972 5.99355L1.26599 9.35728C0.890503 9.73276 0.890503 10.3429 1.26599 10.7184C1.64148 11.0939 2.25164 11.0939 2.62713 10.7184L5.99086 7.35469L9.35459 10.7184C9.73007 11.0939 10.3402 11.0939 10.7157 10.7184C11.0912 10.3429 11.0912 9.73276 10.7157 9.35728L7.352 5.99355Z"
        fill="#EE393C"
      />
    </svg>
  );
};

export default CloseIcon;
