import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
    networkDevices: [],
    networkDeviceToken: {},
    selectedNetwork: {},
    allDevicesLocation: [],
    currentDeviceLocation: {}
}

const name = 'networkDevice'
const parentSelector = state => state?.[name]

const networkDeviceSlice = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        resetNetworkDevice: () => {
            return initialState;
        },
        setNetworkDevices: (state, action) => {
            return {
                ...state,
                networkDevices: action.payload,
            };
        },
        setNetworkDeviceToken: (state, action) => {
            return {
                ...state,
                networkDeviceToken: action.payload,
            };
        },
        setSelectedNetwork: (state, action) => {
            return {
                ...state,
                selectedNetwork: action.payload,
            };
        },
        setAllDevicesLocation: (state, action) => {
            return {
                ...state,
                allDevicesLocation: action.payload,
            };
        },
        setCurrentDeviceLocation: (state, action) => {
            return {
                ...state,
                currentDeviceLocation: action.payload,
            };
        },
    },
});


const getNetworkDevices = createSelector(parentSelector, state => {
    return state?.networkDevices
})

const getNetworkDeviceToken = createSelector(parentSelector, state => {
    return state?.networkDeviceToken
})

const getSelectedNetwork = createSelector(parentSelector, state => {
    return state?.selectedNetwork
})

const getAllDevicesLocation = createSelector(parentSelector, state => {
    return state?.allDevicesLocation
})

const getCurrentDeviceLocation = createSelector(parentSelector, state => {
    return state?.currentDeviceLocation
})

const actions = {
    ...networkDeviceSlice.actions
}

const selectors = {
    getNetworkDevices,
    getNetworkDeviceToken,
    getSelectedNetwork,
    getAllDevicesLocation,
    getCurrentDeviceLocation
}

export const networkDeviceActions = { actions, selectors }


export default networkDeviceSlice;