import React from 'react'

const AppsIcon = ({ fill = "#FFF", width = "18", height = "18" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="Ma8.00933 10.925H3.81476C2.9804 10.925 2.18022 10.5936 1.59025 10.0036C1.00027 9.41364 0.668823 8.61346 0.668823 7.77911V3.58453C0.668823 2.75018 1.00027 1.95 1.59025 1.36002C2.18022 0.770044 2.9804 0.438599 3.81476 0.438599H8.00933C8.84369 0.438599 9.64387 0.770044 10.2338 1.36002C10.8238 1.95 11.1553 2.75018 11.1553 3.58453V7.77911C11.1553 8.61346 10.8238 9.41364 10.2338 10.0036C9.64387 10.5936 8.84369 10.925 8.00933 10.925ZM3.81476 2.53589C3.53664 2.53589 3.26991 2.64637 3.07325 2.84303C2.87659 3.03969 2.76611 3.30641 2.76611 3.58453V7.77911C2.76611 8.05723 2.87659 8.32395 3.07325 8.52061C3.26991 8.71727 3.53664 8.82775 3.81476 8.82775H8.00933C8.28745 8.82775 8.55418 8.71727 8.75084 8.52061C8.9475 8.32395 9.05798 8.05723 9.05798 7.77911V3.58453C9.05798 3.30641 8.9475 3.03969 8.75084 2.84303C8.55418 2.64637 8.28745 2.53589 8.00933 2.53589H3.81476Z" fill={fill} />
            <path d="M8.00933 23.5088H3.81476C2.9804 23.5088 2.18022 23.1773 1.59025 22.5873C1.00027 21.9974 0.668823 21.1972 0.668823 20.3628V16.1682C0.668823 15.3339 1.00027 14.5337 1.59025 13.9437C2.18022 13.3538 2.9804 13.0223 3.81476 13.0223H8.00933C8.84369 13.0223 9.64387 13.3538 10.2338 13.9437C10.8238 14.5337 11.1553 15.3339 11.1553 16.1682V20.3628C11.1553 21.1972 10.8238 21.9974 10.2338 22.5873C9.64387 23.1773 8.84369 23.5088 8.00933 23.5088ZM3.81476 15.1196C3.53664 15.1196 3.26991 15.2301 3.07325 15.4267C2.87659 15.6234 2.76611 15.8901 2.76611 16.1682V20.3628C2.76611 20.6409 2.87659 20.9077 3.07325 21.1043C3.26991 21.301 3.53664 21.4115 3.81476 21.4115H8.00933C8.28745 21.4115 8.55418 21.301 8.75084 21.1043C8.9475 20.9077 9.05798 20.6409 9.05798 20.3628V16.1682C9.05798 15.8901 8.9475 15.6234 8.75084 15.4267C8.55418 15.2301 8.28745 15.1196 8.00933 15.1196H3.81476Z" fill={fill} />
            <path d="M20.5931 10.925H16.3985C15.5641 10.925 14.764 10.5936 14.174 10.0036C13.584 9.41364 13.2526 8.61346 13.2526 7.77911V3.58453C13.2526 2.75018 13.584 1.95 14.174 1.36002C14.764 0.770044 15.5641 0.438599 16.3985 0.438599H20.5931C21.4274 0.438599 22.2276 0.770044 22.8176 1.36002C23.4076 1.95 23.739 2.75018 23.739 3.58453V7.77911C23.739 8.61346 23.4076 9.41364 22.8176 10.0036C22.2276 10.5936 21.4274 10.925 20.5931 10.925ZM16.3985 2.53589C16.1204 2.53589 15.8537 2.64637 15.657 2.84303C15.4603 3.03969 15.3499 3.30641 15.3499 3.58453V7.77911C15.3499 8.05723 15.4603 8.32395 15.657 8.52061C15.8537 8.71727 16.1204 8.82775 16.3985 8.82775H20.5931C20.8712 8.82775 21.1379 8.71727 21.3346 8.52061C21.5312 8.32395 21.6417 8.05723 21.6417 7.77911V3.58453C21.6417 3.30641 21.5312 3.03969 21.3346 2.84303C21.1379 2.64637 20.8712 2.53589 20.5931 2.53589H16.3985Z" fill={fill} />
            <path d="M18.4958 23.5088C17.4588 23.5088 16.4451 23.2012 15.5828 22.6251C14.7206 22.049 14.0485 21.2301 13.6517 20.272C13.2548 19.314 13.151 18.2597 13.3533 17.2426C13.5556 16.2255 14.055 15.2913 14.7883 14.558C15.5215 13.8247 16.4558 13.3254 17.4729 13.1231C18.49 12.9207 19.5442 13.0246 20.5023 13.4214C21.4604 13.8183 22.2792 14.4903 22.8554 15.3526C23.4315 16.2148 23.739 17.2285 23.739 18.2655C23.739 19.6561 23.1866 20.9898 22.2033 21.9731C21.22 22.9563 19.8864 23.5088 18.4958 23.5088ZM18.4958 15.1196C17.8736 15.1196 17.2653 15.3041 16.748 15.6498C16.2307 15.9955 15.8274 16.4868 15.5893 17.0616C15.3512 17.6365 15.2889 18.269 15.4103 18.8793C15.5317 19.4895 15.8313 20.0501 16.2713 20.49C16.7112 20.93 17.2718 21.2296 17.882 21.351C18.4923 21.4724 19.1248 21.4101 19.6997 21.172C20.2745 20.9339 20.7659 20.5307 21.1115 20.0133C21.4572 19.496 21.6417 18.8877 21.6417 18.2655C21.6417 17.4312 21.3103 16.631 20.7203 16.041C20.1303 15.451 19.3301 15.1196 18.4958 15.1196Z" fill={fill} />
        </svg>


    )
}
export default AppsIcon
