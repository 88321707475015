import {
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Group from "./Group";
import styles from "./Groups.module.scss";
import { CREATE_GROUP } from "../../../../../redux/types/group.types";
import CustomButton from "components/shared/Buttons/CustomButton";
import CustomDialog from "components/shared/Dialog/CustomDialog";
import theme from "components/utils/theme";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

const Groups = ({ refreshGroups }) => {
  const dispatch = useDispatch();
  const groups = useSelector((state) => state.group.groups);
  const [selectedType, setSelectedType] = useState("Functional");
  const [open, setOpen] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const handleOpen = (type) => {
    setSelectedType(type);
    reset({
      name: "",
      type: type,
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const onSubmit = (data) => {
    const newGroup = {
      ...data,
      policies: [],
      users: [],
      devices: [],
    };
    dispatch({ type: CREATE_GROUP, payload: newGroup });
    handleClose();
    refreshGroups();
  };

  const descriptions = {
    Functional:
      "A user group is a collection of BDATA users. Use groups to specify permissions for a collection of users.",
    "Location-Based":
      "An Bdata user is an entity that you create in group to represent the person or application that users into interact with Bdata.",
    "Criticality-Based":
      "You can attach up to 20 policies to this user group. All the users in this group will have permissions that are defined in the selected policies.",
    "Vendor/Product":
      "You can attach up to 20 policies to this user group. All the users in this group will have permissions that are defined in the selected policies.",
    "Regulatory Compliance":
      "An Bdata user is an entity that you create in group to represent the person or application that users into interact with Bdata.",
    Custom:
      "An Bdata user is an entity that you create in group to represent the person or application that users into interact with Bdata.",
  };

  return (
    <>
      <div className={styles.container}>
        {Object.keys(groups).map((type) => (
          <Group
            key={type}
            type={type}
            description={descriptions[type]}
            rows={groups[type]}
            handleOpen={() => handleOpen(type)}
            refreshGroups={refreshGroups}
          />
        ))}
      </div>
      <CustomDialog
        title="Create a New Group"
        isOpen={open}
        handleToggle={handleClose}
        submitBottomTitle="Create"
        fullWidth
        noContent
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent
            className={styles.dialogueContent}
            sx={{
              p: 0,
              paddingLeft: "48px",
              paddingRight: "48px",
            }}
          >
            <Typography variant="subtitle1" fontWeight="500">
              Group Name
            </Typography>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              rules={{ required: "Group Name is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="Group Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  error={!!errors.name}
                  helperText={errors.name ? errors.name.message : ""}
                  sx={{ m: 0, marginTop: "10px" }}
                  InputProps={{
                    sx: { height: 40, borderRadius: 2 },
                  }}
                />
              )}
            />
            <FormControl
              variant="outlined"
              fullWidth
              margin="normal"
              error={!!errors.type}
              sx={{ marginTop: "20px" }}
            >
              <Typography variant="subtitle1" fontWeight="500">
                Functional Group
              </Typography>
              <Controller
                name="type"
                control={control}
                defaultValue={selectedType}
                rules={{ required: "Type is required" }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    select
                    fullWidth
                    variant="outlined"
                    sx={{ m: 0, marginTop: "10px" }}
                    InputProps={{
                      sx: { height: 40, borderRadius: 2 },
                    }}
                  >
                    <MenuItem value="Functional">Functional Group</MenuItem>
                    <MenuItem value="Location-Based">
                      Location-Based Group
                    </MenuItem>
                    <MenuItem value="Criticality-Based">
                      Criticality-Based Group
                    </MenuItem>
                    <MenuItem value="Vendor/Product">
                      Vendor/Product Group
                    </MenuItem>
                    <MenuItem value="Regulatory Compliance">
                      Regulatory Compliance Group
                    </MenuItem>
                    <MenuItem value="Custom">Custom Group</MenuItem>
                  </TextField>
                )}
              />
              {errors.type && (
                <FormHelperText>{errors.type.message}</FormHelperText>
              )}
            </FormControl>
          </DialogContent>
          <DialogActions
            sx={{
              p: 0,
              paddingRight: "48px",
              paddingBottom: "40px",
              paddingTop: "40px",
            }}
          >
            <CustomButton
              padding={"0 50px"}
              color={theme.palette.primary.main}
              bgColor={theme.palette.background.default}
              hover={theme.palette.gray.light}
              onClick={handleClose}
              label={"Cancel"}
            />
            <CustomButton
              padding={"0 50px"}
              bgColor={theme.palette.primary.main}
              hover={theme.palette.primary.light}
              label="Create"
              onClick={handleSubmit}
              type="submit"
            />
          </DialogActions>
        </form>
      </CustomDialog>
    </>
  );
};

export default Groups;
