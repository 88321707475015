import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0C5E9B",
      light: "#00B0DF",
    },
    secondary: {
      main: "#FEB72F",
      light: "#FFD600",
    },
    gray: {
      light: "#D0D3D5",
      main: "#9A9AA9",
      bg: "#F7F6FE",
      line: "#C3D4E3",
    },
    white: {
      main: "#FFFFFF",
      dark: "#F2F3F3",
    },
    error: {
      main: "#FF0000",
      light: "#FF6666",
    },
    warning: {
      main: "#ffa000",
      light: "#FFD600",
    },
    brown: {
      main: "#D27218",
      light: "#FEF2E5",
    },
    info: {
      main: "#0288d1",
    },
    success: {
      main: "#00A307",
      light: "#EBF9F1",
    },
    background: {
      default: "#F2F3F3",
      paper: "#FFFFFF",
    },
    text: {
      primary: "#000000",
    },
  },
  typography: {
    allVariants: {
      fontFamily: "Poppins",
      textTransform: "none",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      lgPlus: 1300,
      xlMinus: 1400,
      desktopLarge: 1500,
      xl: 1536,
    },
  },
});

export default theme;
