import theme from "components/utils/theme"

const { ACCESS_MANAGEMENT_TABS } = require("components/utils/accessManagement")
const { computeTotalGroupsCount, countUserRoles } = require("components/utils/policy")
const { default: PeopleIcon } = require("static/svgs/People")
const { default: RegisterSettingsIcon } = require("static/svgs/RegisterSettings")
const { default: UserAppsIcon } = require("static/svgs/UserApps")
const { default: UsersIcon } = require("static/svgs/users")

export const getPortalManagmentResourses = ({ groups, users, policies }) => {
    return [
        {
            count: computeTotalGroupsCount(groups),
            label: "User Group",
            icon: <PeopleIcon />,
            className: "user-group",
            tab: ACCESS_MANAGEMENT_TABS.GROUPS,
        },
        {
            count: users.length,
            label: "Users",
            icon: <UsersIcon />,
            className: "users",
            tab: ACCESS_MANAGEMENT_TABS.USER,
        },
        {
            count: policies?.length,
            label: "Roles",
            icon: <RegisterSettingsIcon />,
            className: "roles",
            tab: ACCESS_MANAGEMENT_TABS.POLICIES,
        },

    ]
}

export const portalCardcolors = [
    theme.palette.primary.light,
    theme.palette.secondary.main,
    theme.palette.primary.main,
    theme.palette.info.main,
];

export const portalNavItems = [
    { label: ACCESS_MANAGEMENT_TABS.GROUPS },
    { label: ACCESS_MANAGEMENT_TABS.USER },
    { label: ACCESS_MANAGEMENT_TABS.ROLES },
]