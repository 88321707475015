import { Box, Card, Typography } from '@mui/material'
import MyTable from 'components/shared/Tables/MyTable/MyTable'
import { getOrgAlertColumns, getOrgDevicePolicyColumns, getOrgDevicesColumns, getOrgEmployeeColumns } from './helper'
import { REMOVE_ALERT_POLICY_FROM_GROUP, REMOVE_DEVICE_FROM_GROUP, REMOVE_DEVICE_POLICY_FROM_GROUP, REMOVE_EMPLOYEE_FROM_GROUP } from "../../../../../redux/types/organization.types"
import { useDispatch } from 'react-redux'

const OrgGroupDetail = ({ currentGroup }) => {
    const dispatch = useDispatch();
    const handleRemoveEmployee = (id) => {

        const payload = {
            empId: id,
            groupId: currentGroup?._id
        }
        dispatch({ type: REMOVE_EMPLOYEE_FROM_GROUP, payload })
    }

    const handleRemoveDevice = (id) => {
        const payload = {
            deviceId: id,
            groupId: currentGroup?._id
        }
        dispatch({ type: REMOVE_DEVICE_FROM_GROUP, payload })
    }

    const handleRemoveAlertPolicy = (id) => {
        const payload = {
            policyId: id,
            groupId: currentGroup?._id
        }
        dispatch({ type: REMOVE_ALERT_POLICY_FROM_GROUP, payload })
    }

    const handleRemoveDevicePolicy = (id) => {
        const payload = {
            policyId: id,
            groupId: currentGroup?._id
        }
        dispatch({ type: REMOVE_DEVICE_POLICY_FROM_GROUP, payload })
    }

    const orgEmployeeColumns = getOrgEmployeeColumns({ handleRemoveEmployee });
    const orgDeviceColumns = getOrgDevicesColumns({ handleRemoveDevice })
    const orgAlertPolicyColumns = getOrgAlertColumns({ handleRemoveAlertPolicy })
    const orgDevicePolicyColumns = getOrgDevicePolicyColumns({ handleRemoveDevicePolicy })
    return (
        <Box sx={{ width: '70vw' }}>
            <Typography variant="h6">Employees</Typography>
            <Card sx={{ height: "300px", my: 2 }}>
                <MyTable
                    columns={orgEmployeeColumns}
                    rows={currentGroup?.employees || []}
                    id={(row) => row?._id}
                />
            </Card>
            <Typography variant="h6">Devices</Typography>
            <Card sx={{ height: "300px", my: 2 }}>
                <MyTable
                    columns={orgDeviceColumns}
                    rows={currentGroup?.devices || []}
                    id={(row) => row?._id}
                />
            </Card>
            <Typography variant="h6">Alert Policies</Typography>
            <Card sx={{ height: "300px", my: 2 }}>
                <MyTable
                    columns={orgAlertPolicyColumns}
                    rows={currentGroup?.alertPolicies || []}
                    id={(row) => row?._id}
                />
            </Card>
            <Typography variant="h6">Device Policies</Typography>
            <Card sx={{ height: "300px", my: 2 }}>
                <MyTable
                    columns={orgDevicePolicyColumns}
                    rows={currentGroup?.devicePolicies || []}
                    id={(row) => row?._id}
                />
            </Card>
        </Box>
    )
}

export default OrgGroupDetail
