import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
    fileAlerts: [],
    alerts: [],
    allAlerts: [],
    multiAlerts: [],
    selectedLevel: "All"
}

const name = 'alerts'
const parentSelector = state => state?.[name]

const alertsSlice = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        resetalerts: () => {
            return initialState;
        },
        setFileAlerts: (state, action) => {
            return {
                ...state,
                fileAlerts: action.payload,
            };
        },
        setAlerts: (state, action) => {
            return {
                ...state,
                alerts: action.payload,
            };
        },
        setAllAlerts: (state, action) => {
            return {
                ...state,
                allAlerts: action.payload,
            };
        },
        setMultiAlerts: (state, action) => {
            return {
                ...state,
                multiAlerts: action.payload,
            };
        },
        setSelectedLevel: (state, action) => {
            return {
                ...state,
                selectedLevel: action.payload,
            };
        },
    },
});

const getFileAlerts = createSelector(parentSelector, state => {
    return state?.fileAlerts
});

const getAlerts = createSelector(parentSelector, state => {
    return state?.alerts
})

const getAllAlerts = createSelector(parentSelector, state => {
    return state?.allAlerts
});

const getSelectedLevel = createSelector(parentSelector, state => {
    return state?.selectedLevel
});

const getMultiAlerts = createSelector(parentSelector, state => {
    return state?.multiAlerts
})

const actions = {
    ...alertsSlice.actions
}

const selectors = {
    getFileAlerts,
    getAlerts,
    getAllAlerts,
    getMultiAlerts,
    getSelectedLevel
}

export const alertsActions = { actions, selectors }

export default alertsSlice;