import { ContentPasteOff } from '@mui/icons-material';
import { Box } from '@mui/material';
import MyCard from 'components/shared/Card/Card';
import theme from 'components/utils/theme';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import Chart from 'react-apexcharts';
import styles from '../../shared/Tables/MyTable/myTable.module.scss';
import GraphTitle from '../MultiDashboard/GraphTitle';

const FimEventsChart = ({ fimData }) => {

    const categories = useMemo(() => {
        if (Array.isArray(fimData?.created)) {
            return fimData.created.map((item) => dayjs(item?.x).format('DD-MMM hh:mm:s a')) // Format the date using dayjs
        }
        return [];
    }, [fimData]);

    const series = useMemo(() => {
        if (Array.isArray(fimData?.created)) {
            return [
                {
                    name: 'Created',
                    data: fimData.created.map((item) => item.y), // High-level threats data
                },
                {
                    name: 'Modified',
                    data: Array.isArray(fimData.modified) ? fimData.modified.map((item) => item.y) : [], // Medium-level threats data
                },
                {
                    name: 'Deleted',
                    data: Array.isArray(fimData.deleted) ? fimData.deleted.map((item) => item.y) : [], // Low-level threats data
                },
            ];
        }
        return []; // Default to empty array if data is not available
    }, [fimData]);

    // Memoize the calculation of the max Y value
    const maxYValue = useMemo(() => {
        const allValues = [
            ...(Array.isArray(fimData?.created) ? fimData.created.map((item) => item.y) : []),
            ...(Array.isArray(fimData?.modified) ? fimData.modified.map((item) => item.y) : []),
            ...(Array.isArray(fimData?.deleted) ? fimData.deleted.map((item) => item.y) : []),
        ];
        return allValues.length ? Math.max(50, Math.max(...allValues)) : 50; // Ensure a minimum value of 50
    }, [fimData]);

    const options = useMemo(() => ({
        chart: {
            type: 'area',
            toolbar: {
                show: false,
            },
        },
        colors: [
            theme.palette.error.main,   // Color for created-level threats
            theme.palette.warning.light, // Color for modified-level threats
            theme.palette.success.main  // Color for deleted-level threats
        ],
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'straight',
            width: 2,
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0,
                stops: [0, 100],
            },
        },
        xaxis: {
            type: 'category',
            categories: categories, // Use the x values (day names) from data
        },
        yaxis: {
            min: 0,
            max: maxYValue, // Adjust max dynamically
            labels: {
                formatter: (value) => Math.round(value),
            },
        },
        grid: {
            borderColor: theme.palette.gray.light,
            strokeDashArray: 4,
            xaxis: {
                lines: {
                    show: true, // Show vertical lines
                },
            },
            yaxis: {
                lines: {
                    show: true, // Show horizontal lines
                },
            },
        },
        tooltip: {
            x: {
                format: 'dd MMM',
            },
        },
    }), [categories, maxYValue]);

    return (
        <MyCard>

            <GraphTitle
                showTimeMenu={false}
                showFullScreenBtn={false}
                title={'File Integrity Over Time'}
            />
            <Box sx={{ px: 5 }}>
                {series.length > 0 ? (
                    <Chart options={options} series={series} type="area" height={240} />
                ) : (
                    <Box className={styles.noData}><ContentPasteOff /> No Data Available</Box>
                )}
            </Box>
        </MyCard>
    );
};

export default FimEventsChart;
