import { yupResolver } from "@hookform/resolvers/yup";
import { string } from "yup";

export const YupEmployeeSchema = {
    email: string().email().required(),
    name: string().required()
}

export const formEmployeeSchema = (employeeSchema) => {
    return {
        resolver: yupResolver(employeeSchema),
        mode: 'onChange',
        defaultValues: {
            email: '',
            name: '',
            department: '',
            phone: ''
        }
    }
}
