import { getBadge } from "components/shared/Tables/helper";
import { formatDateBdata } from "components/utils";

export const devicesColumns = [
    { field: "deviceName", headerName: "Device Name", flex: 1 },
    {
        field: "systemDetails",
        headerName: "OS",
        flex: 1,
        valueGetter: (params) => {
            return params?.os || "Invalid OS";
        },
    },
    {
        field: "ip",
        headerName: "IP",
        flex: 1,
        renderCell: (params) => {
            return params?.row?.systemDetails?.ip;
        },
    },
    {
        field: "status",
        headerName: "Status",
        flex: 1,
        renderCell: (params) => getBadge(params?.row?.status),
    },
    {
        field: "lastActive",
        headerName: "Last Active",
        flex: 1.3,
        renderCell: (params) => {
            return formatDateBdata(params?.row?.lastActive)
        },

    },
];
