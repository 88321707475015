import { Delete, ViewList } from "@mui/icons-material";
import { Box, Button, IconButton } from "@mui/material";
import { format, isValid } from "date-fns";
import { yupResolver } from "@hookform/resolvers/yup";
import { string } from "yup";
import tables from "components/shared/Tables/table.module.scss";
import { getBadge } from "components/shared/Tables/helper";
import { getAccessBadge } from "../../AccessManagement/Policies/helper";
import { formatTimeBdata } from "components/utils";


export const getOrgGroupcolumns = ({ handleDeleteGroup, handleViewDetailsOpen }) => {
    return [
        { field: "name", headerName: "Group Name", flex: 1 },
        {
            field: "employees",
            headerName: "Employees",
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => params?.length || 0,
        },
        {
            field: "devices",
            headerName: "Devices",
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => params?.length || 0,
        },
        {
            field: "alertPolicies",
            headerName: "Alert Policies",
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => params?.length || 0,
        },
        {
            field: "devicePolicies",
            headerName: "Device Policies",
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => params?.length || 0,
        },
        {
            field: "createdAt",
            headerName: "Creation Time",
            flex: 1,
            valueGetter: (params) => {
                const date = new Date(params);
                return isValid(date)
                    ? format(date, "d MMMM, yyyy h:mmaaa")
                    : "Invalid date";
            },
        },
        {
            field: "actions",
            headerName: "Actions",
            flex: 1,
            renderCell: (params) => (
                <>
                    <IconButton
                        onClick={(event) => {
                            event.stopPropagation();
                            handleViewDetailsOpen(params.row);
                        }}
                    >
                        <ViewList />
                    </IconButton>
                    <IconButton
                        onClick={(event) => {
                            // event.stopPropagation();
                            console.log("hand", params?.row?._id)
                            handleDeleteGroup(params.row._id);
                        }}
                    >
                        <Delete />
                    </IconButton>
                </>
            ),
        },
    ]
}



export const YupOrgGroupSchema = {
    name: string().required()
}

export const formOrgGroupSchema = (orgGroupSchema) => {
    return {
        resolver: yupResolver(orgGroupSchema),
        mode: 'onChange',
        defaultValues: {
            name: '',
            type: 'Functional Group',
        }
    }
}

export const GroupOptions = [
    { value: 'Functional Group', label: 'Functional Group' },
    { value: 'Location-Based Group', label: 'Location-Based Group' },
    { value: 'Criticality-Based Group', label: 'Criticality-Based Group' },
    { value: 'Vendor/Product Group', label: 'Vendor/Product Group' },
    { value: 'Regulatory Compliance Group', label: 'Regulatory Compliance Group' },
];




export const getOrgEmployeeColumns = ({ handleRemoveEmployee }) => [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "department", headerName: "Department", flex: 1 },
    {
        field: "actions",
        headerName: "Actions",
        flex: 1,
        renderCell: (params) => (
            <>
                <IconButton
                    onClick={(event) => {
                        // event.stopPropagation();
                        handleRemoveEmployee(params.row._id);
                    }}
                >
                    <Delete />
                </IconButton>
            </>
        ),
    },
];


export const getOrgDevicesColumns = ({ handleRemoveDevice }) => [
    { field: "deviceName", headerName: "Device Name", flex: 1 },
    {
        field: "systemDetails",
        headerName: "OS",
        flex: 1,
        valueGetter: (params) => {
            return params?.os || "Invalid OS";
        },
    },
    {
        field: "ip",
        headerName: "IP",
        flex: 1,
        renderCell: (params) => {
            return params?.row?.systemDetails?.ip;
        },
    },
    {
        field: "status",
        headerName: "Status",
        flex: 1,
        renderCell: (params) => getBadge(params?.row?.status),
    },
    {
        field: "actions",
        headerName: "Actions",
        flex: 1,
        renderCell: (params) => (
            <>
                <IconButton
                    onClick={(event) => {
                        handleRemoveDevice(params.row._id);
                    }}
                >
                    <Delete />
                </IconButton>
            </>
        ),
    },
];


export const getOrgAlertColumns = ({ handleRemoveAlertPolicy }) => {
    return [
        { field: "policyName", headerName: "Policy Name", flex: 1 },
        { field: "type", headerName: "Type", flex: 1 },
        { field: "osPlatform", headerName: "OS Platform", flex: 1 },
        {
            field: "details",
            headerName: "Details",
            minWidth: 100,
            flex: 3,
            renderCell: (params) => (
                <Box sx={{ maxWidth: "100%", overflowX: "auto", display: "flex" }}>
                    <Box sx={{ minWidth: "fit-content" }}>
                        {params.row.details.map((detail, index) => (
                            <Button
                                key={index}
                                variant="outlined"
                                className={tables.actionBtn}
                                size="small"
                                sx={{ marginRight: 1, whiteSpace: "nowrap" }}
                            >
                                {detail}
                            </Button>
                        ))}
                    </Box>
                </Box>
            ),
        },
        {
            field: "size",
            headerName: "Alert Level",
            flex: 1,
            renderCell: (params) => {
                return getAccessBadge(params.row.alertLevel);
            },
        },
        {
            field: "actions",
            headerName: "Actions",
            flex: 1,
            renderCell: (params) => (
                <>
                    <IconButton
                        onClick={() => handleRemoveAlertPolicy(params.row._id)}
                    >
                        <Delete />
                    </IconButton>
                </>
            ),
        },
    ];
}


export const getOrgDevicePolicyColumns = ({ handleRemoveDevicePolicy }) => {
    return [
        { field: "policyName", headerName: "Policy Name", flex: 1 },
        { field: "os", headerName: "OS", flex: 1 },
        {
            field: "details",
            headerName: "Restricted Paths",
            minWidth: 100,
            flex: 2,
            renderCell: (params) => (
                <div style={{ maxWidth: "100%", overflowX: "auto", display: "flex" }}>
                    <div style={{ minWidth: "fit-content" }}>
                        {params.row.paths.map((item, index) => (
                            <Button
                                key={index}
                                variant="outlined"
                                className={tables.actionBtn}
                                size="small"
                                sx={{ marginRight: 1, whiteSpace: "nowrap" }}
                            >
                                {item?.path}
                            </Button>
                        ))}
                    </div>
                </div>
            ),
        },
        {
            field: "restrictedTime",
            headerName: "Restricted Time",
            flex: 1.5,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => `${params?.row?.startDate || ""} ${formatTimeBdata(params?.row?.startTime)} - ${params?.row?.endDate || ""} ${formatTimeBdata(params?.row?.endTime)}`
        },
        {
            field: "actions",
            headerName: "Actions",
            flex: 1,
            renderCell: (params) => (
                <>
                    <IconButton
                        onClick={(event) => {
                            event.stopPropagation();
                            handleRemoveDevicePolicy(params.row._id);
                        }}
                    >
                        <Delete />
                    </IconButton>
                </>
            ),
        },
    ];
}


