export const inputTextFieldStyles = {
    backgroundColor: '#f0f0f0',
    padding: '0 20px',
    input: {
        '::-ms-reveal': {
            display: 'none',
        },
    },
}

export const inputSelectFieldStyles = {
    backgroundColor: '#f0f0f0',
}
