export const GET_ORG_GROUPS = "GET_ORG_GROUPS";
export const CREATE_ORG_GROUP = "CREATE_ORG_GROUP";
export const DELETE_ORG_GROUP = "DELETE_ORG_GROUP";
export const CREATE_DEVICE_POLICY = "CREATE_DEVICE_POLICY";
export const GET_DEVICE_POLICIES = "GET_DEVICE_POLICIES";
export const DELETE_DEVICE_POLICY = "DELETE_DEVICE_POLICY";
export const DELETE_ALERT_POLICY = "DELETE_ALERT_POLICY";


export const ASSIGN_EMPLOYEE = "ASSIGN_EMPLOYEE";
export const ASSIGN_DEVICE = "ASSIGN_DEVICE";
export const ASSIGN_DEVICE_POLIICY = "ASSIGN_DEVICE_POLIICY";

export const ASSIGN_ALERT_POLICY = "ASSIGN_ALERT_POLICY";

export const REMOVE_EMPLOYEE_FROM_GROUP = "REMOVE_EMPLOYEE_FROM_GROUP";
export const REMOVE_DEVICE_FROM_GROUP = "REMOVE_DEVICE_FROM_GROUP";
export const REMOVE_ALERT_POLICY_FROM_GROUP = "REMOVE_ALERT_POLICY_FROM_GROUP";
export const REMOVE_DEVICE_POLICY_FROM_GROUP = "REMOVE_DEVICE_POLICY_FROM_GROUP";