import mapStyles from './deviceMap.module.scss';

export const getMapTooltip = (marker, showNetworkId) => {
  return `<div class="${mapStyles.topologyCard}">
                            <div class="${mapStyles.topologyItem}">
                              <strong>${marker?.device?.deviceName}</strong>
                              <button
                                data-id="${marker?.device?._id}"
                                class="${mapStyles.topologyButton}"
                              >
                                Details
                            <svg width="7" height="7" viewBox="0 0 7 7" fill="white" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.96223 6.5541C6.98374 4.2127 6.97181 2.05872 7 0C4.66019 0.0237434 2.50195 0.00903593 0.445926 0.0377846C0.438518 0.573275 0.436892 0.981249 0.430026 1.49294C1.83363 1.48608 3.28517 1.48012 4.50275 1.47392L0.101503 5.87519C-0.0332052 6.00988 -0.0338557 6.25427 0.0996962 6.38827L0.611426 6.89996C0.745375 7.03391 0.989802 7.03328 1.12453 6.89815L5.52576 2.49687C5.51383 3.85312 5.52124 5.20945 5.50274 6.5656C5.99662 6.56562 6.52965 6.55494 6.96189 6.55368L6.96223 6.5541Z" fill={"black"} />
                            </svg>
                        </button>

                            </div>
                          
                            <div class="${mapStyles.topologyItem}">
                              <div style="display: flex; align-items: center; gap: 3px;">
                                <svg width="15" height="15" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.7 8.9375H7.15388C7.14 9.36311 6.87806 9.625 6.54 9.625H5.4C5.04956 9.625 4.78088 9.24967 4.78556 8.9375H0.3C0.135 8.9375 0 9.09219 0 9.28125V9.625C0 10.3813 0.54 11 1.2 11H10.8C11.46 11 12 10.3813 12 9.625V9.28125C12 9.09219 11.865 8.9375 11.7 8.9375ZM10.8 1.03125C10.8 0.464063 10.395 0 9.9 0H2.1C1.605 0 1.2 0.464063 1.2 1.03125V8.25H10.8V1.03125ZM9.6 6.875H2.4V1.375H9.6V6.875Z" fill="#0B5D9B" />
                                </svg>
                                <span class="${mapStyles.topologyHeading}">Device ID</span>
                              </div>
                              <span class="${mapStyles.topologyText}">
                                ${marker?.device?.deviceId || ''}
                              </span>
                            </div>
                            ${showNetworkId ? `
                    <div class="${mapStyles.topologyItem}">
                        <div style="display: flex; align-items: center; gap: 3px;">
                             <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.5625 8.5625C9.285 8.5625 9.02625 8.63937 8.80125 8.7725L7.69687 7.66813C7.92562 7.33625 8.06063 6.93313 8.06063 6.5C8.06063 6.06687 7.92562 5.66375 7.69687 5.33188L8.80125 4.2275C9.02437 4.35875 9.28312 4.4375 9.5625 4.4375C10.3894 4.4375 11.0625 3.76437 11.0625 2.9375C11.0625 2.11063 10.3894 1.4375 9.5625 1.4375C8.73563 1.4375 8.0625 2.11063 8.0625 2.9375C8.0625 3.215 8.13937 3.47375 8.2725 3.69875L7.16813 4.80313C6.83625 4.57438 6.43313 4.43937 6 4.43937C5.56687 4.43937 5.16375 4.57438 4.83188 4.80313L3.7275 3.69875C3.85875 3.47562 3.9375 3.21687 3.9375 2.9375C3.9375 2.11063 3.26437 1.4375 2.4375 1.4375C1.61063 1.4375 0.9375 2.11063 0.9375 2.9375C0.9375 3.76437 1.61063 4.4375 2.4375 4.4375C2.715 4.4375 2.97375 4.36062 3.19875 4.2275L4.30313 5.33188C4.07438 5.66375 3.93937 6.06687 3.93937 6.5C3.93937 6.93313 4.07438 7.33625 4.30313 7.66813L3.19875 8.7725C2.97562 8.64125 2.71687 8.5625 2.4375 8.5625C1.61063 8.5625 0.9375 9.23563 0.9375 10.0625C0.9375 10.8894 1.61063 11.5625 2.4375 11.5625C3.26437 11.5625 3.9375 10.8894 3.9375 10.0625C3.9375 9.785 3.86062 9.52625 3.7275 9.30125L4.83188 8.19687C5.16375 8.42562 5.56687 8.56063 6 8.56063C6.43313 8.56063 6.83625 8.42562 7.16813 8.19687L8.2725 9.30125C8.14125 9.52437 8.0625 9.78312 8.0625 10.0625C8.0625 10.8894 8.73563 11.5625 9.5625 11.5625C10.3894 11.5625 11.0625 10.8894 11.0625 10.0625C11.0625 9.23563 10.3894 8.5625 9.5625 8.5625Z" fill="#0C5E9B" />
                            </svg>
                            <span class="${mapStyles.topologyHeading}">Network ID</span>
                        </div>
                        <span class="${mapStyles.topologyText}">
                            ${marker?.device?.networkId || ''}
                        </span>
                    </div>` : ``
    }
                              <div class="${mapStyles.topologyItem}">
                                <div style="display: flex; align-items: center; gap: 3px;">
                                  <svg width="15px" height="15px" viewBox="0 0 27 28" fill="#0B5D9B" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.1333 7.58017V12.099H6.12207C6.16316 10.0751 6.45392 8.10524 6.9754 6.34863C7.10814 6.4091 7.24088 6.46956 7.37678 6.52684C8.88748 7.16966 10.4867 7.52289 12.1333 7.58017Z" fill={#0B5D9B} />
                                        <path d="M19.0291 10.7399C18.7889 10.8163 18.5518 10.9054 18.318 11.0072C17.6732 11.2904 17.0854 11.6596 16.5639 12.1019H13.0811V7.5831C14.7277 7.52582 16.3269 7.17259 17.8407 6.52977C17.9766 6.47249 18.1094 6.41203 18.2421 6.35156C18.6467 7.71039 18.9121 9.18378 19.0291 10.7399Z" fill={#0B5D9B} />
                                        <path d="M12.1335 13.0537V17.5725C10.332 17.633 8.60011 18.0467 6.97247 18.8041C6.45099 17.0475 6.16023 15.0776 6.11914 13.0537H12.1335Z" fill={#0B5D9B} />
                                        <path d="M14.0357 17.5757C14.0357 17.598 14.0357 17.6203 14.0357 17.6394C13.7197 17.6044 13.4004 17.5821 13.0781 17.5725V13.0537H15.6317C14.6204 14.3075 14.0357 15.8955 14.0357 17.5757Z" fill={#0B5D9B} />
                                        <path d="M0 12.0988C0.0568884 10.5649 0.385577 9.07565 0.979745 7.6659C1.56443 6.28162 2.37983 5.0278 3.40699 3.93311C3.48284 4.0063 3.56185 4.07631 3.64086 4.1495C3.71987 4.21951 3.79888 4.28952 3.8779 4.35953C4.57004 4.95461 5.31591 5.47332 6.11235 5.91247C5.53398 7.79956 5.21161 9.91895 5.17053 12.0988H0Z" fill={#0B5D9B} />
                                        <path d="M6.11528 19.243C5.31884 19.6853 4.57297 20.204 3.88083 20.7959C3.80181 20.8659 3.7228 20.9327 3.64379 21.0059C3.56478 21.076 3.48577 21.1491 3.40992 21.2223C2.38276 20.1276 1.5642 18.8738 0.982675 17.4895C0.388507 16.0798 0.0598181 14.5905 0.00292969 13.0566H5.17346C5.21454 15.2333 5.53691 17.3559 6.11528 19.243Z" fill={#0B5D9B} />
                                        <path d="M4.32079 3.48151C4.24178 3.4115 4.16277 3.33831 4.08691 3.26512C5.58182 1.89675 7.35484 0.926155 9.27324 0.416992C9.12154 0.55383 8.973 0.700214 8.82446 0.859327C8.81498 0.868874 8.80549 0.878421 8.79601 0.89115C8.65695 1.04072 8.52105 1.19983 8.38831 1.36849C7.614 2.3359 6.94714 3.57062 6.4225 4.99945C5.76828 4.62395 5.14567 4.18798 4.56415 3.69473C4.48197 3.62153 4.3998 3.55152 4.32079 3.48151Z" fill={#0B5D9B} />
                                        <path d="M16.6083 24.459C16.3618 24.5449 16.1121 24.6244 15.8593 24.6945C15.992 24.5576 16.1248 24.4049 16.2543 24.2426C16.2923 24.1948 16.3302 24.1471 16.3681 24.0962C16.3871 24.1248 16.406 24.1567 16.425 24.1853C16.4851 24.2776 16.5451 24.3699 16.6083 24.459Z" fill={#0B5D9B} />
                                        <path d="M9.26692 24.7357C7.34852 24.2234 5.5755 23.2528 4.08691 21.8908C4.16277 21.8176 4.24178 21.7476 4.32079 21.6776C4.3998 21.6076 4.48197 21.5376 4.56098 21.4676C5.14251 20.9775 5.76196 20.5415 6.4225 20.166C6.95346 21.6139 7.61716 22.8359 8.38199 23.7906C8.51473 23.9593 8.65379 24.1184 8.79285 24.268C8.94772 24.4303 9.10574 24.5894 9.26692 24.7357Z" fill={#0B5D9B} />
                                        <path d="M12.0671 0.00318222C12.0892 0.00318222 12.1114 0.00318227 12.1335 0V6.62229C10.4268 6.55865 8.78024 6.15132 7.26953 5.43531C7.8479 3.81554 8.62221 2.46307 9.51346 1.50839C9.74734 1.25699 9.99069 1.03424 10.2372 0.8433C10.449 0.677823 10.667 0.534621 10.8883 0.416877C11.2738 0.210029 11.6689 0.0700098 12.0671 0.00318222Z" fill={#0B5D9B} />
                                        <path d="M7.27235 19.7175C8.80202 18.9888 10.4328 18.591 12.1332 18.5273V25.1496C12.1079 25.1496 12.0857 25.1496 12.0605 25.1465C11.6654 25.0796 11.2735 24.9396 10.8879 24.7296C10.6667 24.6087 10.4518 24.4655 10.2369 24.3032C9.98088 24.1059 9.7312 23.8767 9.491 23.6158C8.60923 22.6643 7.8444 21.3246 7.27235 19.7175Z" fill={#0B5D9B} />
                                        <path d="M15.9065 23.3641L15.7833 23.2782C15.521 23.6665 15.2333 23.9974 14.9268 24.2679C14.7435 24.4302 14.5507 24.5734 14.3516 24.6943C14.0008 24.9107 13.6278 25.0571 13.2391 25.1335C13.1854 25.1367 13.1316 25.1398 13.0811 25.143V18.5303C13.4256 18.543 13.7701 18.5716 14.1082 18.6098C14.2631 19.7936 14.6834 21.0538 15.3598 22.3713C15.5241 22.699 15.7074 23.03 15.9065 23.3641Z" fill={#0B5D9B} />
                                        <path d="M20.5338 3.78996C19.9838 4.24184 19.4023 4.64599 18.7955 4.99285C18.2962 3.62766 17.6609 2.43749 16.9308 1.48918C16.7981 1.31734 16.6622 1.15186 16.5231 0.995928C16.4789 0.948194 16.4378 0.90046 16.3936 0.852726C16.2798 0.7318 16.1628 0.614056 16.0459 0.505859C17.8853 1.05003 19.5856 2.0238 21.0173 3.3699C20.9383 3.44309 20.8593 3.5131 20.7803 3.58311C20.6949 3.65312 20.6159 3.72313 20.5338 3.78996Z" fill={#0B5D9B} />
                                        <path d="M24.9488 11.523C24.8382 11.453 24.7276 11.3862 24.6138 11.3225C24.5032 11.2589 24.3894 11.2016 24.2756 11.1443C23.3085 10.667 22.2371 10.4156 21.1499 10.4156C20.7485 10.4156 20.3535 10.4474 19.9616 10.5142C19.8288 8.8849 19.5381 7.3415 19.0988 5.91266C19.8446 5.49896 20.5463 5.01844 21.2036 4.46791C21.2858 4.40108 21.3648 4.33107 21.4438 4.26106C21.5229 4.19105 21.6019 4.12104 21.6809 4.04785C22.6606 5.11391 23.4381 6.32954 24.0038 7.66609C24.5253 8.89762 24.8413 10.1928 24.9488 11.523Z" fill={#0B5D9B} />
                                        <path d="M15.7011 1.51179C16.5955 2.46647 17.3667 3.81894 17.945 5.43871C16.4343 6.15472 14.7877 6.56205 13.0811 6.6257V0.00976562C13.1285 0.0129479 13.179 0.0129478 13.2265 0.0161301C13.6247 0.0925044 14.0197 0.242071 14.4053 0.461647C14.6265 0.588938 14.8415 0.735322 15.0564 0.907164C15.2744 1.08855 15.4893 1.28904 15.7011 1.51179Z" fill={#0B5D9B} />
                                        <path d="M24.9805 13.13C24.9489 13.1014 24.9172 13.0759 24.8856 13.0505C24.8161 12.99 24.7434 12.9327 24.6707 12.8786C24.5696 12.8023 24.4653 12.7259 24.3578 12.6559C23.4097 12.029 22.2909 11.6885 21.1468 11.6885C20.3377 11.6885 19.5539 11.8508 18.8207 12.1722C16.6811 13.1078 15.2999 15.2303 15.2999 17.5757C15.2999 17.617 15.2999 17.6584 15.2999 17.6998C15.3157 18.5367 15.5054 19.4405 15.8593 20.3952V20.3983C16.0269 20.8502 16.2354 21.3148 16.4788 21.7922C16.4978 21.8335 16.5199 21.8717 16.542 21.9131C16.8043 22.4095 17.1046 22.9219 17.4459 23.4406C17.5028 23.5297 17.5628 23.6188 17.626 23.7079C17.664 23.7652 17.7019 23.8225 17.743 23.8766C17.7651 23.9084 17.7872 23.9402 17.8094 23.972C19.0672 25.7668 20.4673 27.1988 21.15 27.8576C22.5501 26.4987 27 21.8526 27 17.5789C26.9968 15.8636 26.2636 14.2502 24.9805 13.13ZM21.15 19.3705C20.1671 19.3705 19.3675 18.5654 19.3675 17.5757C19.3675 16.586 20.1671 15.7809 21.15 15.7809C22.1329 15.7809 22.9325 16.586 22.9325 17.5757C22.9325 18.5654 22.1329 19.3705 21.15 19.3705Z" fill={#0B5D9B} />
                                    </svg>
                                  <span class="${mapStyles.topologyHeading}">IP</span>
                                </div>
                                <span class="${mapStyles.topologyText}">
                                  ${marker?.device?.systemDetails?.ip}
                                </span>
                              </div>
                          </div>
                    `;
}