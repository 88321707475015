import { Grid } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux';
import { object } from 'yup';
import { formEmployeeSchema, YupEmployeeSchema } from './helper';
import { useForm } from 'react-hook-form';
import CustomTextField from 'components/shared/Forms/CustomTextField';
import CustomButton from 'components/shared/Buttons/CustomButton';
import theme from 'components/utils/theme';
import { CREATE_EMPLOYEE } from '../../../../../redux/types/employee.types';

const EmployeeForm = ({ handleIsEmployeeClose }) => {

    const dispatch = useDispatch();
    let loginSchema = object(YupEmployeeSchema);
    const { control, handleSubmit, formState: { errors, isValid } } = useForm(formEmployeeSchema(loginSchema))

    const handleEmployeeSubmit = (payload) => {
        payload.orgId = "xzzzo"
        dispatch({ type: CREATE_EMPLOYEE, payload })
        handleIsEmployeeClose();
    };

    return (
        <form onSubmit={handleSubmit(handleEmployeeSubmit)}>
            <Grid container spacing={2}>

                <Grid item xs={6}>
                    <CustomTextField
                        control={control}
                        errors={errors}
                        name={'name'}
                        type="text"
                        label="Name"
                        borderRadius='5px'
                        required={true}
                        placeHolder="Employee Name"
                    />
                </Grid>
                <Grid item xs={6}>
                    <CustomTextField
                        control={control}
                        errors={errors}
                        name={'email'}
                        type="text"
                        label="Email Address"
                        borderRadius='5px'
                        required={true}
                        placeHolder="xyz@gmail.com"
                    />
                </Grid>
                <Grid item xs={6}>
                    <CustomTextField
                        control={control}
                        errors={errors}
                        name={'department'}
                        type="text"
                        label="Department"
                        borderRadius='5px'
                        required={false}
                        placeHolder="Department"
                    />
                </Grid>
                <Grid item xs={6}>
                    <CustomTextField
                        control={control}
                        errors={errors}
                        name={'phone'}
                        type="text"
                        label="Phone Number"
                        borderRadius='5px'
                        required={false}
                        placeHolder="Phone Number"
                    />
                </Grid>

                <Grid item xs={6}>

                </Grid>
                <Grid item xs={3}>
                    <CustomButton
                        padding={"0 50px"}
                        color={theme.palette.primary.main}
                        bgColor={theme.palette.background.default}
                        hover={theme.palette.gray.light}
                        label={"Cancel"}
                        fullWidth={true}
                        onClick={handleIsEmployeeClose}
                    />
                </Grid>
                <Grid item xs={3}>
                    <CustomButton
                        padding={"0 50px"}
                        color={theme.palette.background.default}
                        bgColor={theme.palette.primary.main}
                        hover={theme.palette.info.main}
                        label={"Add"}
                        type={"submit"}
                        fullWidth={true}
                        disabled={!isValid}
                    />
                </Grid>
            </Grid>
            <br />
        </form>
    )
}

export default EmployeeForm
