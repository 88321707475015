import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
    devicePolicy: [],
}

const name = 'devicePolicy'
const parentSelector = state => state?.[name]

const devicePolicySlice = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        setDevicePolicy: (state, action) => {
            return {
                ...state,
                devicePolicy: action.payload,
            };
        },
    },
});


const getDevicePolicy = createSelector(parentSelector, state => {
    return state?.devicePolicy
})

const actions = {
    ...devicePolicySlice.actions
}

const selectors = {
    getDevicePolicy
}

export const devicePolicyActions = { actions, selectors }


export default devicePolicySlice;