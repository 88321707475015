import { Grid } from '@mui/material';
import CustomButton from 'components/shared/Buttons/CustomButton';
import CustomSelect from 'components/shared/Forms/CustomSelect';
import CustomTextField from 'components/shared/Forms/CustomTextField';
import theme from 'components/utils/theme';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { object } from 'yup';
import { CREATE_ORG_GROUP } from '../../../../../redux/types/organization.types';
import { formOrgGroupSchema, GroupOptions, YupOrgGroupSchema } from './helper';

const GroupForm = ({ handleIsOrgGroupClose }) => {

    const dispatch = useDispatch();
    let orgGroupSchema = object(YupOrgGroupSchema);
    const { control, handleSubmit, formState: { errors, isValid } } = useForm(formOrgGroupSchema(orgGroupSchema))

    const handleOrgGroupSubmit = (payload) => {
        payload.orgId = "xzzzo"
        dispatch({ type: CREATE_ORG_GROUP, payload })
        handleIsOrgGroupClose();
    };

    return (
        <form onSubmit={handleSubmit(handleOrgGroupSubmit)}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <CustomTextField
                        control={control}
                        errors={errors}
                        name={'name'}
                        type="text"
                        label="Name"
                        borderRadius='5px'
                        required={true}
                        placeHolder="Group Name"
                    />
                </Grid>

                <Grid item xs={12}>
                    <CustomSelect
                        control={control}
                        name="type"
                        label="Select Group"
                        required={false}
                        options={GroupOptions}
                        borderRadius='5px'
                    />
                </Grid>
                <Grid item xs={6}>
                    <CustomButton
                        padding={"0 50px"}
                        color={theme.palette.primary.main}
                        bgColor={theme.palette.gray.light}
                        hover={theme.palette.gray.light}
                        label={"Cancel"}
                        fullWidth={true}
                        onClick={handleIsOrgGroupClose}
                        type={"button"}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CustomButton
                        padding={"0 50px"}
                        color={theme.palette.background.default}
                        bgColor={theme.palette.primary.main}
                        hover={theme.palette.info.main}
                        label={"Add"}
                        type={"submit"}
                        fullWidth={true}
                        disabled={!isValid}
                    />
                </Grid>
            </Grid>
            <br />
        </form>
    )
}

export default GroupForm
