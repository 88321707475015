import { Add } from "@mui/icons-material";
import {
  Card,
  Grid
} from "@mui/material";
import CustomTitle from "components/shared/CustomTitle";
import CustomDialog from "components/shared/Dialog/CustomDialog";
import MyTable from "components/shared/Tables/MyTable/MyTable";
import MyTableToolbar from "components/shared/Tables/MyTable/MyTableToolbar";
import { handleDownloadCsv, handlePrintCsv } from "components/utils/handlers";
import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { getDevicesSocket } from "../../../../../redux/saga/socket";
import { authActions } from "../../../../../redux/slice/auth.slice";
import { devicesActions } from "../../../../../redux/slice/devices.slice";
import { orgGroupsActions } from "../../../../../redux/slice/orgGroups.slice";
import { GET_DEVICES } from "../../../../../redux/types/devices.types";
import { ASSIGN_DEVICE } from "../../../../../redux/types/organization.types";
import OrgGroupSelector from "../OrgGroupSelector";
import { devicesColumns } from "./helper";


const Devices = () => {
  const groups = useSelector(orgGroupsActions.selectors.getOrgGroups)
  const dispatch = useDispatch();
  const currentUser = useSelector(authActions.selectors.getCurrentUser);
  const devices = useSelector(devicesActions.selectors.getDevices);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const handleOpen = () => {
    if (selectedDevices.length === 0) {
      toast.error("Please choose device first");
      return;
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedGroup("");
  };

  useEffect(() => {
    getDevicesSocket({
      orgId: process.env.REACT_APP_ORG_ID,
      addedBy: currentUser?._id,
    });
  }, [currentUser?._id]);

  useEffect(() => {
    const payload = {
      orgId: process.env.REACT_APP_ORG_ID,
    };
    dispatch({ type: GET_DEVICES, payload });
  }, [dispatch]);

  const handleAddDeviceToGroup = async () => {
    const payload = {
      devIds: selectedDevices,
      groupId: selectedGroup
    }
    dispatch({ type: ASSIGN_DEVICE, payload });
    handleClose();
  };

  const handleSearchChange = (term) => {
    setSearchQuery(term);
  };


  const filteredDevices = useMemo(() => {
    if (!searchQuery) return devices;
    const regex = new RegExp(searchQuery, "i");
    return devices?.filter((pkg) =>
      Object.values(pkg).some((value) => regex.test(value))
    );
  }, [searchQuery, devices]);

  const handleSelectedDevices = (ids) => {
    setSelectedDevices(ids)
  }

  return (
    <Grid container >
      <Grid item xs={12}>
        <CustomTitle title={"Devices"} count={devices?.length} />
        <br />
        <Card>
          <MyTableToolbar
            placeHolder={"Search Devices"}
            btn2Txt={"Add Devices to Group"}
            btn2Icon={Add}
            onSearch={handleSearchChange}
            handleBtn2Click={handleOpen}
            onDownload={() => handleDownloadCsv(devices, devicesColumns)}
            onPrint={() => handlePrintCsv(devices, devicesColumns)}
          />
          <MyTable
            checkboxSelection={true}
            columns={devicesColumns}
            rows={filteredDevices}
            id={(row) => row?._id}
            handleSelectedRows={handleSelectedDevices}
          />
        </Card>
      </Grid>

      <CustomDialog title="Select Group" isOpen={open} handleToggle={handleClose} maxWidth={"md"}>
        <OrgGroupSelector groups={groups} handleAddToGroup={handleAddDeviceToGroup} selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup} handleClose={handleClose} />
      </CustomDialog>

    </Grid>
  );
};

export default Devices;
