import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
    employees: []
}

const name = 'employees'
const parentSelector = state => state?.[name]

const employeesSlice = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        resetEmployees: () => {
            return initialState;
        },
        setEmployees: (state, action) => {
            return {
                ...state,
                employees: action.payload,
            };
        }
    },
});


const getEmployees = createSelector(parentSelector, state => {
    return state?.employees
})


const actions = {
    ...employeesSlice.actions
}

const selectors = {
    getEmployees
}

export const employeesActions = { actions, selectors }


export default employeesSlice;