import { Box, Typography } from "@mui/material";
import { memo } from "react";
import MyCard from "../../shared/Card/Card";
import text from "../../shared/css/text.module.scss";
import theme from "../../utils/theme";
import PieChart from "./PieChart";
const TotalAlerts = ({ total }) => {
  const chartData = { a: 9, b: 20, c: 30, d: 23 };
  const graphColors = [
    theme.palette.gray.light,
    theme.palette.primary.main,
    theme.palette.primary.light,
    theme.palette.secondary.main,
  ];

  const tempHandleClick = (data) => {
    console.log("data", data)
  }
  return (
    <MyCard padding={2}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography className={text.heading2}>Total Alerts</Typography>
      </Box>
      <Box mt={3} display={"flex"} justifyContent={"center"}>
        <PieChart
          chartData={chartData}
          width={400}
          height={260}
          graphColors={graphColors}
          totalValue={total}
          radius={120}
          left={"43.5%"}
          showLabels={true}
          totalText={"Total"}
          handleClick={tempHandleClick}
        />
      </Box>
    </MyCard>
  );
};

export default memo(TotalAlerts);
