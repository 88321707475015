import { MenuItem, Select } from '@mui/material';
import React from 'react';
import text from '../css/text.module.scss'; // Your existing text styles
import forms from './forms.module.scss'; // Your existing styles
import { inputSelectFieldStyles } from './helper';

const CustomSelect = ({ label, required, options, value, onChange, borderRadius = "20px", defaultValue = options?.[0]?.value }) => {
    return (
        <div className={forms.customTextField}>
            {label && (
                <label className={`${text.formLabel} ${forms.label}`}>
                    {label}{required && <span className={forms.estaric}>*</span>}
                </label>
            )}
            <Select
                value={value}
                onChange={onChange}
                displayEmpty
                defaultValue={defaultValue}
                style={{
                    ...inputSelectFieldStyles,
                    borderRadius: borderRadius
                }}
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    },
                    getContentAnchorEl: null
                }}
            >
                {options.map((option, index) => (
                    <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                ))}
            </Select>
        </div>
    );
};

export default CustomSelect;
