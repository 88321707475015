import { createSlice, createSelector } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';

const initialState = {
  alertPolicies: [],
  status: "idle",
  error: null,
};

const name = 'alertPolicy';
const parentSelector = (state) => state?.[name];

const alertPolicySlice = createSlice({
  name: name,
  initialState,
  reducers: {
    resetAlertPolicies: () => initialState,
    fetchAlertPoliciesRequest: (state) => {
      return {
        ...state,
        status: "loading",
      };
    },
    fetchAlertPoliciesSuccess: (state, action) => {
      return {
        ...state,
        status: "succeeded",
        alertPolicies: action.payload,
      };
    },
    fetchAlertPoliciesFailure: (state, action) => {
      return {
        ...state,
        status: "failed",
        error: action.payload,
      };
    },
    createAlertPolicyRequest: (state) => {
      return {
        ...state,
        status: "loading",
      };
    },
    createAlertPolicySuccess: (state, action) => {
      toast.success('Alert Policy Created');
      return {
        ...state,
        status: "succeeded",
        alertPolicies: [...state.alertPolicies, action.payload],
      };
    },
    createAlertPolicyFailure: (state, action) => {
      toast.error("Something went wrong");
      return {
        ...state,
        status: "failed",
        error: action.payload,
      };
    },
  },
});

// Selectors
const getAlertPolicies = createSelector(parentSelector, state => {
  return state?.alertPolicies
});
const getAlertPolicyStatus = createSelector(parentSelector, (state) => state?.status);
const getAlertPolicyError = createSelector(parentSelector, (state) => state?.error);

const actions = {
  ...alertPolicySlice.actions,
};

const selectors = {
  getAlertPolicies,
  getAlertPolicyStatus,
  getAlertPolicyError,
};

// Exporting actions and selectors under a single object
export const alertPolicyActions = { actions, selectors };

// Exporting the reducer as default
export default alertPolicySlice;
