import { yupResolver } from "@hookform/resolvers/yup";
import { CheckCircle } from "@mui/icons-material";
import { Button } from "@mui/material";
import theme from "components/utils/theme";
import PeopleIcon from "static/svgs/People";
import RegisterSettingsIcon from "static/svgs/RegisterSettings";
import UsersIcon from "static/svgs/users";
import { ref, string } from "yup"; // Importing necessary methods from Yup
import tables from "../../../shared/Tables/table.module.scss";

export const deviceUserItems = [
    { label: "File / Folder Access" },
    { label: "Time Based Access" },
    { label: "Device Administration" }
];


export const getDeviceUserItems = (total, active, registered) => {
    return (
        [
            { value: 34, label: 'Device Id', icon: <PeopleIcon />, color: theme.palette.primary.light },
            { value: 45, label: 'Device Name', icon: <UsersIcon />, color: theme.palette.secondary.main },
            { value: 43, label: 'User Name', icon: <RegisterSettingsIcon />, color: theme.palette.primary.main },
            { value: 43, label: 'Role', icon: <RegisterSettingsIcon />, color: theme.palette.primary.main },
            { value: 43, label: 'Group', icon: <RegisterSettingsIcon />, color: theme.palette.primary.main },
        ]
    )
}

export const getRestrictedPathColumns = () => {
    return [
        { field: "path", headerName: "Path", width: 120, flex: 1.5 },
        {
            field: "date",
            headerName: "Date",
            width: 70,
            flex: 0.7,
        },
        { field: "time", headerName: "Time", width: 100, flex: 0.4 },
        {
            field: "action",
            headerName: "Action",
            width: 100,
            flex: 1,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    endIcon={<CheckCircle className={tables.icon} />}
                    // onClick={() => handleAccessManagement(params?.row)}
                    className={tables.actionBtn}
                    size="small"
                >
                    Allow path
                </Button>
            ),
        },
    ];
}

export const dummyPaths = [
    { id: 1, path: "C:/Windows/System32", date: "3-12-2024", time: "11:30 am" },
    { id: 2, path: "C:/Program Files", date: "3-12-2024", time: "12:15 pm" },
    { id: 3, path: "C:/Users/Administrator", date: "3-12-2024", time: "1:00 pm" },
    { id: 4, path: "C:/Windows/Temp", date: "3-12-2024", time: "2:45 pm" },
    { id: 5, path: "D:/Confidential/ProjectFiles", date: "3-12-2024", time: "3:30 pm" },
    { id: 6, path: "D:/Backup/SystemBackups", date: "3-12-2024", time: "4:00 pm" }
];

export const weekDays = [
    { label: "Monday", value: "Mon" },
    { label: "Tuesday", value: "Tue" },
    { label: "Wednesday", value: "Wed" },
    { label: "Thursday", value: "Thu" },
    { label: "Friday", value: "Fri" },
    { label: "Saturday", value: "Sat" },
    { label: "Sunday", value: "Sun" },
];



export const YupResetPasswordSchema = {
    password: string().required("Password is required"),
    confirmPassword: string()
        .oneOf([ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
}

export const formResetPasswordSchema = (resetPasswordSchema) => {
    return {
        resolver: yupResolver(resetPasswordSchema),
        mode: "onChange",
        defaultValues: {
            password: "",
            confirmPassword: "",
        },
    };
};
