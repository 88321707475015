import apiInterceptor from "./Interceptors/ApiInterceptor";


export const getOrgGroupsServ = async (payload) => {
    try {
        const response = await apiInterceptor.get(`/get-orgGroups`, {
            params: payload
        });
        return response?.data;
    } catch (error) {
        throw error;
    }
}


export const createOrgGroupServ = async (payload) => {
    try {
        const response = await apiInterceptor.post(`/create-orgGroup`, payload);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const deleteOrgGroupServ = async (payload) => {
    try {
        console.log("payl", payload)
        const response = await apiInterceptor.delete(`/delete-orgGroup/${payload}`);
        return response?.data;
    } catch (error) {
        throw error;
    }
}

//device Policy

export const createDevicePolicyServ = async (payload) => {
    try {
        const response = await apiInterceptor.post(`/create-devicePolicy`, payload);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const getDevicePolicyServ = async (payload) => {
    try {
        const response = await apiInterceptor.get(`/get-devicePolicy`, {
            params: payload
        });
        return response?.data;
    } catch (error) {
        throw error;
    }
}


export const deleteDevicePolicyServ = async (payload) => {
    try {
        const response = await apiInterceptor.delete(`/delete-devicePolicy/${payload}`);
        return response?.data;
    } catch (error) {
        throw error;
    }
}


//assigning

export const assignEmployeeServ = async (payload) => {
    try {
        const response = await apiInterceptor.post(`/assign-employees-groups`, payload);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const assignDeviceServ = async (payload) => {
    try {
        const response = await apiInterceptor.post(`/assign-devices-groups`, payload);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const assignAlertPolicyServ = async (payload) => {
    try {
        const response = await apiInterceptor.post(`/assign-alertPolicy-groups`, payload);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const assignDevicePolicyServ = async (payload) => {
    try {
        const response = await apiInterceptor.post(`/assign-devicePolicy-groups`, payload);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const removeEmployeeFromGroupServ = async (payload) => {
    try {
        const response = await apiInterceptor.post(`/remove-employee-groups`, payload);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const removeDeviceFromGroupServ = async (payload) => {
    try {
        const response = await apiInterceptor.post(`/remove-device-groups`, payload);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const removeAlertPolicyFromGroupServ = async (payload) => {
    try {
        const response = await apiInterceptor.post(`/remove-alertPolicy-groups`, payload);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const removeDevicePolicyFromGroupServ = async (payload) => {
    try {
        const response = await apiInterceptor.post(`/remove-devicePolicy-groups`, payload);
        return response?.data;
    } catch (error) {
        throw error;
    }
};
