import { Add, GroupAdd } from '@mui/icons-material'
import { Card, Grid } from '@mui/material'
import CustomTitle from 'components/shared/CustomTitle'
import CustomDialog from 'components/shared/Dialog/CustomDialog'
import MyTable from 'components/shared/Tables/MyTable/MyTable'
import MyTableToolbar from 'components/shared/Tables/MyTable/MyTableToolbar'
import { handleDownloadCsv, handlePrintCsv } from 'components/utils/handlers'
import { useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { employeesActions } from '../../../../../redux/slice/employees.slice'
import { DELETE_EMPLOYEES, GET_EMPLOYEES } from '../../../../../redux/types/employee.types'
import { employeeColumns } from '../helper'
import OrgGroupSelector from '../OrgGroupSelector'
import EmployeeForm from './EmployeeForm'
import { orgGroupsActions } from '../../../../../redux/slice/orgGroups.slice'
import { ASSIGN_EMPLOYEE } from '../../../../../redux/types/organization.types'

const Employees = () => {
    const [employeeSearchTerm, setEmployeeSearchTerm] = useState("");
    const orgGroups = useSelector(orgGroupsActions.selectors.getOrgGroups)
    const dispatch = useDispatch()
    useEffect(() => {
        const payload = {
            orgId: 'xzzzo'
        }
        dispatch({ type: GET_EMPLOYEES, payload })
    }, [dispatch])

    const employees = useSelector(employeesActions.selectors.getEmployees);


    const handleEmployeeSearch = (term) => {
        setEmployeeSearchTerm(term);
    };

    const filtereddEmployees = useMemo(() => {
        if (!employeeSearchTerm) return employees;
        const regex = new RegExp(employeeSearchTerm, "i");
        return employees.filter((pkg) =>
            Object.values(pkg).some((value) => regex.test(value))
        );
    }, [employeeSearchTerm, employees]);

    const [isEmployeeOpen, setIsEmployeeOpen] = useState(false);
    const handleEmployeeOpen = () => {
        setIsEmployeeOpen(true)
    }
    const handleIsEmployeeClose = () => {
        setIsEmployeeOpen(false)
    }

    const [selectedEmployees, setSelectedEmployees] = useState([])
    const handleSelectedRows = (ids) => {
        setSelectedEmployees(ids);
    }

    const handleDeleteEmployees = () => {
        dispatch({ type: DELETE_EMPLOYEES, payload: { ids: selectedEmployees } })
    }



    const [selectedGroup, setSelectedGroup] = useState({})
    const [openGroup, setOpenGroup] = useState(false);
    const handleGroupOpen = () => {
        setOpenGroup(true)
    }
    const handleGroupClose = () => {
        setOpenGroup(false)
    }

    const handleAddEmployee = () => {
        if (!selectedEmployees?.length) {
            toast.error("Please Select the Employees First");
            return
        }
        handleGroupOpen()
        console.log("Selected", selectedEmployees);
    }

    const handleAddToGroup = () => {
        const payload = {
            empIds: selectedEmployees,
            groupId: selectedGroup
        }
        dispatch({ type: ASSIGN_EMPLOYEE, payload });
        handleGroupClose();
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <CustomTitle title={"Employees"} count={filtereddEmployees?.length} />
                    <br />
                    <Card>
                        <MyTableToolbar
                            placeHolder={"Search Employees"}
                            btn2Txt={"Add Employee"}
                            btn1Txt={"Assign Employee to Group"}
                            btn1Icon={GroupAdd}
                            btn2Icon={Add}
                            handleBtn1Click={handleAddEmployee}
                            handleBtn2Click={handleEmployeeOpen}
                            onSearch={handleEmployeeSearch}
                            onDownload={() => handleDownloadCsv(filtereddEmployees, employeeColumns)}
                            onPrint={() => handlePrintCsv(filtereddEmployees, employeeColumns)}
                            isUninstall={selectedEmployees?.length ? true : false}
                            handleDeleteClick={handleDeleteEmployees}
                        />
                        <MyTable
                            checkboxSelection={true}
                            columns={employeeColumns}
                            rows={filtereddEmployees}
                            id={(row) => row?._id}
                            handleSelectedRows={handleSelectedRows}
                        />
                    </Card>
                </Grid>



            </Grid>
            <br />
            <CustomDialog title={'Add Employee'} isOpen={isEmployeeOpen} handleToggle={handleIsEmployeeClose} maxWidth={"md"}>
                <EmployeeForm handleIsEmployeeClose={handleIsEmployeeClose} />
            </CustomDialog>

            <CustomDialog title="Select Group" isOpen={openGroup} handleToggle={handleGroupClose} maxWidth={"md"}>
                <OrgGroupSelector groups={orgGroups} handleAddToGroup={handleAddToGroup} selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup} handleClose={handleGroupClose} />
            </CustomDialog>
        </>
    )
}

export default Employees
