import { Add, GroupAdd } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Card,
  Chip,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import CustomButton from "components/shared/Buttons/CustomButton";
import CustomTitle from "components/shared/CustomTitle";
import CustomDialog from "components/shared/Dialog/CustomDialog";
import MyTable from "components/shared/Tables/MyTable/MyTable";
import MyTableToolbar from "components/shared/Tables/MyTable/MyTableToolbar";
import { alertOptions } from "components/utils/accessManagement";
import { handleDownloadCsv, handlePrintCsv } from "components/utils/handlers";
import { ErrorMessage, Form, Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { alertPolicyActions } from "../../../../../redux/slice/alertPolicy.slice";
import { orgGroupsActions } from "../../../../../redux/slice/orgGroups.slice";
import { CREATE_ALERT_POLICY, FETCH_ALERT_POLICIES } from "../../../../../redux/types/alertPolicy.types";
import { ASSIGN_ALERT_POLICY, DELETE_ALERT_POLICY } from "../../../../../redux/types/organization.types";
import OrgGroupSelector from "../../OrganizationManagement/OrgGroupSelector";
import { getAlertColumns } from "./helper";
import styles from "./Policies.module.scss";

const PolicyAlerts = () => {
  const dispatch = useDispatch();
  const groups = useSelector(orgGroupsActions.selectors.getOrgGroups)
  const alertPolicies = useSelector(alertPolicyActions.selectors.getAlertPolicies);

  const theme = useTheme();
  const [alertOpen, setAlertOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("");
  const [assignOpen, setAssignOpen] = useState(false);
  const [selectedPolicies, setSelectedPolicies] = useState([]);
  const [selectedOS, setSelectedOS] = useState("windows");
  const [selectedType, setSelectedType] = useState("FIM");
  const [alertLevel, setAlertLevel] = useState("Low");

  const [deleteAlertPolicyOpen, setDeleteAlertPolicyOpen] = useState(false);
  const [selectedAlertPolicy, setSelectedAlertPolicy] = useState(null);

  useEffect(() => {
    const payload = {
      orgId: 'xzzzo'
    }
    dispatch({ type: FETCH_ALERT_POLICIES, payload })
  }, [dispatch])
  const handleSelectedRows = (ids) => {
    setSelectedPolicies(ids);
  }

  const handleAlertOpen = () => {
    setAlertOpen(true);
  };

  const handleAssignOpen = () => {
    if (selectedPolicies.length === 0) {
      toast.error("Please choose policy first");
      return;
    }
    setAssignOpen(true);
  };

  const handleAssignClose = () => {
    setAssignOpen(false);
    setSelectedGroup("");
    setSelectedPolicies([]);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleCreatePolicy = (values) => {
    dispatch({ type: CREATE_ALERT_POLICY, payload: values });
    setAlertOpen(false);
  };

  const filteredAlerts = useMemo(() => {
    if (!searchQuery) return alertPolicies;
    const regex = new RegExp(searchQuery, "i");
    return alertPolicies.filter((pkg) =>
      Object.values(pkg).some((value) => regex.test(value))
    );
  }, [searchQuery, alertPolicies]);

  const handleAssignPolicyToGroup = async () => {
    const payload = { groupId: selectedGroup, policyIds: selectedPolicies }
    dispatch({ type: ASSIGN_ALERT_POLICY, payload });
    handleAssignClose();
  };

  const validationSchema = Yup.object({
    policyName: Yup.string().required("Policy Name is required"),
    type: Yup.string().required("Type is required"),
    osPlatform: Yup.string().required("OS Platform is required"),
    details: Yup.array().min(1, "At least one detail is required"),
  });

  const handleDeleteAlertPolicyOpen = (id) => {
    setSelectedAlertPolicy(id);
    setDeleteAlertPolicyOpen(true);
  };

  const alertColumns = getAlertColumns(handleDeleteAlertPolicyOpen);

  const handleDeleteAlertPolicyClose = () => {
    setDeleteAlertPolicyOpen(false);
  };

  const handleDeleteAlertPolicy = async () => {

    dispatch({ type: DELETE_ALERT_POLICY, payload: selectedAlertPolicy });
    handleDeleteAlertPolicyClose()
  };

  const handleAlertPolicySearch = (term) => {
    setSearchQuery(term);
  };
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <CustomTitle title={"Alert Policies"} count={filteredAlerts?.length || 0} infoText={"Alert policy is a Notification in Bdata that Defines an Alert."} />
          <br />
          <Card>
            <MyTableToolbar
              placeHolder={"Search Alert Polcies"}
              btn2Txt={"Add Alert Policy"}
              btn1Txt={"Assign Policy to Group"}
              btn1Icon={GroupAdd}
              btn2Icon={Add}
              handleBtn2Click={handleAlertOpen}
              handleBtn1Click={handleAssignOpen}
              onSearch={handleAlertPolicySearch}
              onDownload={() => handleDownloadCsv(filteredAlerts, alertColumns)}
              onPrint={() => handlePrintCsv(filteredAlerts, alertColumns)}
            />
            <MyTable
              checkboxSelection={true}
              columns={alertColumns}
              rows={filteredAlerts}
              id={(row) => row?._id}
              handleSelectedRows={handleSelectedRows}
            />
          </Card>
        </Grid>

        {/* Assign Policies to Group Dialog */}
        <CustomDialog title="Select Group" isOpen={assignOpen} handleToggle={handleAlertClose} maxWidth={"md"} >
          <OrgGroupSelector groups={groups} handleAddToGroup={handleAssignPolicyToGroup} selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup} handleClose={handleAlertClose} />
        </CustomDialog >

        {/* Create New Alert Policy Dialog */}
        <CustomDialog
          title=" Add Alert Policy"
          isOpen={alertOpen}
          handleToggle={handleAlertClose}
          fullWidth
          noContent
        >
          <Formik
            initialValues={{
              policyName: "",
              type: "Port",
              osPlatform: "windows",
              alertLevel,
              details: [],
            }}
            validationSchema={validationSchema}
            onSubmit={handleCreatePolicy}
          >
            {({ values, handleChange, setFieldValue, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <DialogContent
                  sx={{
                    p: 0,
                    paddingLeft: "48px",
                    paddingRight: "48px",
                  }}
                >
                  <Box display="flex" flexDirection="column" gap={0}>
                    <FormControl fullWidth margin="normal">
                      <Typography variant="subtitle1" fontWeight="500">
                        Policy Name
                      </Typography>
                      <TextField
                        name="policyName"
                        placeholder="Policy Name"
                        value={values.policyName}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          sx: { height: 40, borderRadius: 2 },
                        }}
                      />
                      <ErrorMessage
                        name="policyName"
                        component="div"
                        className={styles.error}
                      />
                    </FormControl>
                    <Box display="flex" justifyContent="space-between" gap={2}>
                      <FormControl fullWidth margin="normal">
                        <Typography variant="subtitle1" fontWeight="500">
                          Select OS
                        </Typography>
                        <Select
                          name="osPlatform"
                          value={values.osPlatform}
                          onChange={(e) => {
                            handleChange(e);
                            setSelectedOS(e.target.value);
                          }}
                          sx={{ height: 40, borderRadius: 2 }}
                        >
                          <MenuItem value="windows">Windows</MenuItem>
                          <MenuItem value="ubuntu">Ubuntu</MenuItem>
                        </Select>
                        <ErrorMessage
                          name="osPlatform"
                          component="div"
                          className={styles.error}
                        />
                      </FormControl>
                      <FormControl fullWidth margin="normal">
                        <Typography variant="subtitle1" fontWeight="500">
                          Select Type
                        </Typography>
                        <Select
                          name="type"
                          value={values.type}
                          onChange={(e) => {
                            handleChange(e);
                            setSelectedType(e.target.value);
                          }}
                          sx={{ height: 40, borderRadius: 2 }}
                        >
                          <MenuItem value="Port">Port</MenuItem>
                          <MenuItem value="FIM">FIM</MenuItem>
                          <MenuItem value="Process">Process</MenuItem>
                        </Select>
                        <ErrorMessage
                          name="type"
                          component="div"
                          className={styles.error}
                        />
                      </FormControl>
                      <FormControl fullWidth margin="normal">
                        <Typography variant="subtitle1" fontWeight="500">
                          Alert Level
                        </Typography>
                        <Select
                          name="alertLevel"
                          value={values.alertLevel}
                          onChange={(e) => {
                            handleChange(e);
                            setAlertLevel(e.target.value);
                          }}
                          sx={{ height: 40, borderRadius: 2 }}
                        >
                          <MenuItem value="Low">Low</MenuItem>
                          <MenuItem value="Medium">Medium</MenuItem>
                          <MenuItem value="High">High</MenuItem>
                        </Select>
                        <ErrorMessage
                          name="alertLevel"
                          component="div"
                          className={styles.error}
                        />
                      </FormControl>
                    </Box>
                    <FormControl fullWidth margin="normal">
                      <Typography variant="subtitle1" fontWeight="500">
                        Black List
                      </Typography>
                      <Autocomplete
                        multiple
                        freeSolo
                        value={values.details}
                        onChange={(event, newValue) =>
                          setFieldValue("details", newValue)
                        }
                        options={alertOptions[selectedOS][selectedType]}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip
                              label={option}
                              {...getTagProps({ index })}
                              onDelete={() => {
                                const newDetails = values.details.filter(
                                  (item) => item !== option
                                );
                                setFieldValue("details", newDetails);
                              }}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Type and press enter to add"
                            onKeyDown={(event) => {
                              if (
                                event.key === "Enter" &&
                                event.target.value.trim() !== ""
                              ) {
                                event.preventDefault();
                                const newDetail = event.target.value.trim();
                                setFieldValue("details", [
                                  ...values.details,
                                  newDetail,
                                ]);
                                event.target.value = "";
                              }
                            }}
                            variant="outlined"
                            fullWidth
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                borderRadius: 2,
                              },
                            }}
                          />
                        )}
                      />
                      <ErrorMessage
                        name="details"
                        component="div"
                        className={styles.error}
                      />
                    </FormControl>
                  </Box>
                </DialogContent>

                <DialogActions
                  sx={{
                    p: 0,
                    paddingRight: "48px",
                    paddingBottom: "40px",
                    paddingTop: "40px",
                  }}
                >
                  <CustomButton
                    padding={"0 50px"}
                    color={theme.palette.primary.main}
                    bgColor={theme.palette.background.default}
                    hover={theme.palette.gray.light}
                    onClick={handleAlertClose}
                    label={"Cancel"}
                  />
                  <CustomButton
                    padding={"0 50px"}
                    bgColor={theme.palette.primary.main}
                    hover={theme.palette.primary.light}
                    label="Add"
                    onClick={handleSubmit}
                    type="submit"
                  />
                </DialogActions>
              </Form>
            )}
          </Formik>
        </CustomDialog>

        {/* Delete Alert Policy */}
        <CustomDialog CustomDialog
          title="Delete Alert Policy"
          isOpen={deleteAlertPolicyOpen}
          handleToggle={handleDeleteAlertPolicyClose}
          submitBottomTitle="Invite"
          fullWidth
          noContent
        >
          <DialogContent
            sx={{
              p: 0,
              paddingLeft: "48px",
              paddingRight: "48px",
            }}
          >
            <Typography>
              Are you sure you want to delete this alert policy?
            </Typography>
          </DialogContent>

          <DialogActions
            sx={{
              p: 0,
              paddingRight: "48px",
              paddingBottom: "40px",
              paddingTop: "40px",
            }}
          >
            <CustomButton
              padding={"0 50px"}
              color={theme.palette.primary.main}
              bgColor={theme.palette.background.default}
              hover={theme.palette.gray.light}
              onClick={handleDeleteAlertPolicyClose}
              label={"Cancel"}
            />
            <CustomButton
              padding={"0 50px"}
              bgColor={theme.palette.error.main}
              hover={theme.palette.error.light}
              label="Delete"
              onClick={handleDeleteAlertPolicy}
            />
          </DialogActions>
        </CustomDialog >
      </Grid>

    </>

  );
};

export default PolicyAlerts;
