import { Add } from "@mui/icons-material";
import {
    Card,
    Grid
} from "@mui/material";
import CustomTitle from "components/shared/CustomTitle";
import CustomDialog from "components/shared/Dialog/CustomDialog";
import MyTable from "components/shared/Tables/MyTable/MyTable";
import MyTableToolbar from "components/shared/Tables/MyTable/MyTableToolbar";
import { handleDownloadCsv, handlePrintCsv } from "components/utils/handlers";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { orgGroupsActions } from "../../../../../redux/slice/orgGroups.slice";
import { DELETE_ORG_GROUP, GET_ORG_GROUPS } from "../../../../../redux/types/organization.types";
import GroupForm from "./GroupForm";
import { getOrgGroupcolumns } from "./helper";
import OrgGroupDetail from "./OrgGroupDetail";

const OrgGroups = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const payload = {
            orgId: 'xzzzo'
        }
        dispatch({ type: GET_ORG_GROUPS, payload });
    }, [dispatch])

    const orgGroups = useSelector(orgGroupsActions.selectors.getOrgGroups)
    const [isOrgGroupOpen, setIsOrgGroupOpen] = useState(false);
    const handleOrgGroupOpen = () => {
        setIsOrgGroupOpen(true)
    }
    const handleIsOrgGroupClose = () => {
        setIsOrgGroupOpen(false)
    }

    const handleDeleteGroup = (id) => {
        dispatch({ type: DELETE_ORG_GROUP, payload: id })
    }


    const [orgGroupsSearchTerm, setOrgGroupsTerm] = useState(null);

    const handleOrgGroupsSearch = (term) => {
        setOrgGroupsTerm(term);
    };
    const filteredGroups = useMemo(() => {
        if (!orgGroupsSearchTerm) return orgGroups;
        const regex = new RegExp(orgGroupsSearchTerm, "i");
        return orgGroups.filter((grp) =>
            Object.values(grp).some((value) => regex.test(value))
        );
    }, [orgGroupsSearchTerm, orgGroups]);

    const [viewOrgGroup, setViewOrgGroup] = useState(false);
    const currentGroup = useSelector(orgGroupsActions.selectors.getSelectedGroup)
    const handleViewDetailsClose = () => {
        dispatch(orgGroupsActions.actions.setSelectedGroup(null))
        setViewOrgGroup(false);
    };

    const handleViewDetailsOpen = (row) => {
        dispatch(orgGroupsActions.actions.setSelectedGroup(row))
        setViewOrgGroup(true);
    };

    const orgGroupColumns = getOrgGroupcolumns({ handleDeleteGroup, handleViewDetailsOpen })

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <CustomTitle title={"Groups"} count={orgGroups?.length} infoText={"A user group is a collection of BDATA users. Use groups to specify permissions for a collection of users."} />
                    <br />
                    <Card>
                        <MyTableToolbar
                            placeHolder={"Search Groups"}
                            btn2Txt={"Create Group"}
                            btn2Icon={Add}
                            handleBtn2Click={handleOrgGroupOpen}
                            onSearch={handleOrgGroupsSearch}
                            onDownload={() => handleDownloadCsv(orgGroupColumns, orgGroupColumns)}
                            onPrint={() => handlePrintCsv(orgGroupColumns, orgGroupColumns)}
                        />
                        <MyTable
                            columns={orgGroupColumns}
                            rows={filteredGroups}
                            id={(row) => row?._id}
                        />
                    </Card>
                </Grid>

            </Grid>
            <br />

            <CustomDialog title={'Create Group'} isOpen={isOrgGroupOpen} handleToggle={handleIsOrgGroupClose} maxWidth={"xs"}>
                <GroupForm handleIsOrgGroupClose={handleIsOrgGroupClose} />
            </CustomDialog>

            <CustomDialog title={'Group Details'} isOpen={viewOrgGroup} handleToggle={handleViewDetailsClose} maxWidth={"xl"}>
                <OrgGroupDetail currentGroup={currentGroup} />
            </CustomDialog>
        </>
    );
};

export default OrgGroups;
