import toast from "react-hot-toast";
import { put, select, takeEvery } from "redux-saga/effects";
import { SOMETHING_WENT_WRONG } from "../../static/constants";

import { orgGroupsActions } from "../slice/orgGroups.slice";

import { removeAlertPolicyService } from "../services/alertPolicy.service";
import { assignAlertPolicyServ, assignDevicePolicyServ, assignDeviceServ, assignEmployeeServ, createDevicePolicyServ, createOrgGroupServ, deleteDevicePolicyServ, deleteOrgGroupServ, getDevicePolicyServ, getOrgGroupsServ, removeAlertPolicyFromGroupServ, removeDeviceFromGroupServ, removeDevicePolicyFromGroupServ, removeEmployeeFromGroupServ } from "../services/organization.service";
import { alertPolicyActions } from "../slice/alertPolicy.slice";
import { devicePolicyActions } from "../slice/devicePolicy.slice";
import { ASSIGN_ALERT_POLICY, ASSIGN_DEVICE, ASSIGN_DEVICE_POLIICY, ASSIGN_EMPLOYEE, CREATE_DEVICE_POLICY, CREATE_ORG_GROUP, DELETE_ALERT_POLICY, DELETE_DEVICE_POLICY, DELETE_ORG_GROUP, GET_DEVICE_POLICIES, GET_ORG_GROUPS, REMOVE_ALERT_POLICY_FROM_GROUP, REMOVE_DEVICE_FROM_GROUP, REMOVE_DEVICE_POLICY_FROM_GROUP, REMOVE_EMPLOYEE_FROM_GROUP } from "../types/organization.types";

export function* getOrgGroups(action) {
    try {
        const orgGroups = yield getOrgGroupsServ(action.payload);
        console.log("orgGroupsorgGroups", orgGroups)
        if (orgGroups) {
            yield put(orgGroupsActions.actions.setOrgGroups(orgGroups?.data));
        }
    } catch (err) {
        console.log("err", err);
        toast.error(SOMETHING_WENT_WRONG);
    }
}

export function* createOrgGroup(action) {
    try {
        const orgGroup = yield createOrgGroupServ(action.payload);
        if (orgGroup) {
            const prevOrgGroups = yield select(orgGroupsActions.selectors.getOrgGroups)
            yield put(orgGroupsActions.actions.setOrgGroups([orgGroup?.data, ...prevOrgGroups]));
            toast.success("OrgGroup Created Successfully");
        }
    } catch (err) {
        console.log("err", err);
        toast.error(SOMETHING_WENT_WRONG);
    }
}

export function* deleteOrgGroups(action) {
    try {
        const response = yield deleteOrgGroupServ(action.payload);
        if (response) {
            const orgGroups = yield select(orgGroupsActions.selectors.getOrgGroups);
            const updatedOrgGroups = orgGroups.filter((group) => group?._id !== response?.data?._id);
            yield put(orgGroupsActions.actions.setOrgGroups(updatedOrgGroups));
            toast.success("Group Deleted Successfully");
        }
    } catch (err) {
        console.log("err", err);
        toast.error(SOMETHING_WENT_WRONG);
    }
}

//Device Policy

export function* createDevicePolicy(action) {
    try {
        const devicePolicy = yield createDevicePolicyServ(action.payload);
        if (devicePolicy) {

            console.log("devicePolicy", devicePolicy);
            const prevDevicePolicy = yield select(devicePolicyActions.selectors.getDevicePolicy)
            yield put(devicePolicyActions.actions.setDevicePolicy([devicePolicy?.data, ...prevDevicePolicy]));
            toast.success("Device Policy Created Successfully");
        }
    } catch (err) {
        console.log("err", err);
        toast.error(SOMETHING_WENT_WRONG);
    }
}

export function* getDevicePolicySaga(action) {
    try {
        const devicePolicy = yield getDevicePolicyServ(action.payload);
        console.log("getDevicePolicyServ", devicePolicy)
        if (devicePolicy) {
            yield put(devicePolicyActions.actions.setDevicePolicy(devicePolicy?.data));
        }
    } catch (err) {
        console.log("err", err);
        toast.error(SOMETHING_WENT_WRONG);
    }
}

export function* deleteDevicePolicy(action) {
    try {
        const response = yield deleteDevicePolicyServ(action.payload);
        if (response) {
            console.log("respo", response?.data)
            const devicePolicy = yield select(devicePolicyActions.selectors.getDevicePolicy);
            const updatedDevicePolicy = devicePolicy.filter((policy) => policy?._id !== response?.data?._id);
            yield put(devicePolicyActions.actions.setDevicePolicy(updatedDevicePolicy));
            toast.success("Group Deleted Successfully");
        }
    } catch (err) {
        console.log("err", err);
        toast.error(SOMETHING_WENT_WRONG);
    }
}

export function* assignEmployeeSaga(action) {
    try {
        const orgGroup = yield assignEmployeeServ(action.payload);
        if (orgGroup) {
            toast.success("Employee Added Successfully");
        }
    } catch (err) {
        console.log("err", err);
        toast.error(SOMETHING_WENT_WRONG);
    }
}

export function* assignDeviceSaga(action) {
    try {
        const orgGroup = yield assignDeviceServ(action.payload);
        if (orgGroup) {
            toast.success("Device Added Successfully");
        }
    } catch (err) {
        console.log("err", err);
        toast.error(SOMETHING_WENT_WRONG);
    }
}

export function* assignDevicePolicySaga(action) {
    try {
        const orgGroup = yield assignDevicePolicyServ(action.payload);
        if (orgGroup) {
            toast.success("Device Policy Assigned to Group");
        }
    } catch (err) {
        console.log("err", err);
        toast.error(SOMETHING_WENT_WRONG);
    }
}



export function* deleteAlertPolicySaga(action) {
    try {
        const alertPolicy = yield removeAlertPolicyService(action.payload);
        if (alertPolicy) {
            const prevAlertPolicies = yield select(alertPolicyActions.selectors.getAlertPolicies);
            const updatedDevicePolicy = prevAlertPolicies.filter((policy) => policy?._id !== alertPolicy?._id);
            yield put(alertPolicyActions.actions.fetchAlertPoliciesSuccess(updatedDevicePolicy));
            toast.success("Alert Policy Deleted Successfully");
        }
    } catch (err) {
        console.log("err", err);
        toast.error(SOMETHING_WENT_WRONG);
    }
}

export function* assignAlertPolicySaga(action) {
    try {
        const orgGroup = yield assignAlertPolicyServ(action.payload);
        if (orgGroup) {
            toast.success("Alert Policy Assigned to Group");
        }
    } catch (err) {
        console.log("err", err);
        toast.error(SOMETHING_WENT_WRONG);
    }
}


export function* removeEmployeeFromGroupSaga(action) {
    try {
        const orgGroup = yield removeEmployeeFromGroupServ(action.payload);
        if (orgGroup) {
            yield put(orgGroupsActions.actions.setSelectedGroup(orgGroup?.data));
            const prevOrgGroups = yield select(orgGroupsActions.selectors.getOrgGroups);
            const updatedOrgGroups = prevOrgGroups.filter((group) => group?._id !== orgGroup?.data?._id);
            yield put(orgGroupsActions.actions.setOrgGroups([orgGroup?.data, ...updatedOrgGroups]));
            toast.success("Employee Removed from Group");
        }
    } catch (err) {
        console.log("err", err);
        toast.error(SOMETHING_WENT_WRONG);
    }
}

export function* removeDeviceFromGroupSaga(action) {
    try {
        const orgGroup = yield removeDeviceFromGroupServ(action.payload);
        if (orgGroup) {
            yield put(orgGroupsActions.actions.setSelectedGroup(orgGroup?.data));

            const prevOrgGroups = yield select(orgGroupsActions.selectors.getOrgGroups);
            const updatedOrgGroups = prevOrgGroups.filter((group) => group?._id !== orgGroup?.data?._id);
            yield put(orgGroupsActions.actions.setOrgGroups([orgGroup?.data, ...updatedOrgGroups]));
            toast.success("Device Removed from Group");
        }
    } catch (err) {
        console.log("err", err);
        toast.error(SOMETHING_WENT_WRONG);
    }
}


export function* removeAlertPolicyFromGroupSaga(action) {
    try {
        const orgGroup = yield removeAlertPolicyFromGroupServ(action.payload);
        if (orgGroup) {
            yield put(orgGroupsActions.actions.setSelectedGroup(orgGroup?.data));

            const prevOrgGroups = yield select(orgGroupsActions.selectors.getOrgGroups);
            const updatedOrgGroups = prevOrgGroups.filter((group) => group?._id !== orgGroup?.data?._id);
            yield put(orgGroupsActions.actions.setOrgGroups([orgGroup?.data, ...updatedOrgGroups]));
            toast.success("Alert Policy Removed from Group");
        }
    } catch (err) {
        console.log("err", err);
        toast.error(SOMETHING_WENT_WRONG);
    }
}

export function* removeDevicePolicyFromGroupSaga(action) {
    try {
        const orgGroup = yield removeDevicePolicyFromGroupServ(action.payload);
        if (orgGroup) {
            yield put(orgGroupsActions.actions.setSelectedGroup(orgGroup?.data));

            const prevOrgGroups = yield select(orgGroupsActions.selectors.getOrgGroups);
            const updatedOrgGroups = prevOrgGroups.filter((group) => group?._id !== orgGroup?.data?._id);
            yield put(orgGroupsActions.actions.setOrgGroups([orgGroup?.data, ...updatedOrgGroups]));
            toast.success("Device Policy Removed from Group");
        }
    } catch (err) {
        console.log("err", err);
        toast.error(SOMETHING_WENT_WRONG);
    }
}

export function* watchOrgGroupAsync() {
    yield takeEvery(GET_ORG_GROUPS, getOrgGroups);
    yield takeEvery(CREATE_ORG_GROUP, createOrgGroup);
    yield takeEvery(DELETE_ORG_GROUP, deleteOrgGroups);
    yield takeEvery(CREATE_DEVICE_POLICY, createDevicePolicy);
    yield takeEvery(GET_DEVICE_POLICIES, getDevicePolicySaga);
    yield takeEvery(DELETE_DEVICE_POLICY, deleteDevicePolicy);
    yield takeEvery(ASSIGN_EMPLOYEE, assignEmployeeSaga);
    yield takeEvery(ASSIGN_DEVICE, assignDeviceSaga);
    yield takeEvery(ASSIGN_DEVICE_POLIICY, assignDevicePolicySaga);
    yield takeEvery(ASSIGN_ALERT_POLICY, assignAlertPolicySaga);
    yield takeEvery(DELETE_ALERT_POLICY, deleteAlertPolicySaga);

    yield takeEvery(REMOVE_EMPLOYEE_FROM_GROUP, removeEmployeeFromGroupSaga);
    yield takeEvery(REMOVE_DEVICE_FROM_GROUP, removeDeviceFromGroupSaga);
    yield takeEvery(REMOVE_ALERT_POLICY_FROM_GROUP, removeAlertPolicyFromGroupSaga);
    yield takeEvery(REMOVE_DEVICE_POLICY_FROM_GROUP, removeDevicePolicyFromGroupSaga);
}
