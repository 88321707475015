import { Lock } from '@mui/icons-material'
import { Card, Grid, TextField, Typography } from '@mui/material'
import CustomButton from 'components/shared/Buttons/CustomButton'
import MyCard from 'components/shared/Card/Card'
import theme from 'components/utils/theme'
import table from "../../../shared/Tables/table.module.scss";
import CustomTitle from 'components/shared/CustomTitle'
import MyTableToolbar from 'components/shared/Tables/MyTable/MyTableToolbar'
import MyTable from 'components/shared/Tables/MyTable/MyTable'
import { useMemo } from 'react'
import { dummyPaths, getRestrictedPathColumns } from './helper'

const FileFolderAccess = () => {

    const fileFilderColumns = useMemo(() => {
        return getRestrictedPathColumns()
    }, [])

    return (
        <Grid container>
            <MyCard height='120px' padding={3}>
                <Grid container alignItems={'center'}>
                    <Grid item xs={4} display={'flex'} flexDirection={'column'} gap={1}>
                        <Typography variant='h6'>Specify Directory For Access Control</Typography>
                        <Typography variant='subtitle2'>Enter complete path of your directory</Typography>
                    </Grid>
                    <Grid item xs={6} display={'flex'} flexDirection={'column'} gap={1}>
                        <TextField id="outlined-basic" placeholder="e.g., D:/Admin, D:/Confidential" size="medium" variant="outlined" />
                    </Grid>
                    <Grid item xs={2} display={'flex'} justifyContent={'center'}>
                        <CustomButton Icon={Lock} bgColor={theme.palette.primary.main} hover={theme.palette.primary.light} label={"Restrict"} />
                    </Grid>
                </Grid>
            </MyCard>

            <Grid item xs={12} xl={6} className={table.tableContainer}>
                <CustomTitle title={"Restricted Paths"} count={4} />
                <Card>
                    <MyTableToolbar
                        placeHolder={"Search For Paths"}
                    // onSearch={handleNetworkSearch}
                    // onDownload={() => handleDownloadCsv(filteredNetworks, networkColumns)}
                    // onPrint={() => handlePrintCsv(filteredNetworks, networkColumns)}
                    />
                    <MyTable
                        checkboxSelection={true}
                        columns={fileFilderColumns}
                        rows={dummyPaths}
                    />
                </Card>
            </Grid>
        </Grid>
    )
}

export default FileFolderAccess
