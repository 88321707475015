import { Box, Typography } from "@mui/material";
import { memo } from "react";
import MyCard from "../../shared/Card/Card";
import text from "../../shared/css/text.module.scss";
import VerticalBarChart from "./VerticleBarChart";
const DeviceGraph = () => {
  return (
    <MyCard padding={2} >
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography className={text.heading2}>Total Event In</Typography>
      </Box>
      <VerticalBarChart />
    </MyCard>
  );
};

export default memo(DeviceGraph);
