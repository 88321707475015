import { Add, ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Paper, Typography } from '@mui/material';
import { osOptions } from 'components/modules/Device/helper';
import { weekDays } from 'components/modules/SingleDashboard/DeviceUserPopup/helper';
import { IOSSwitch } from 'components/modules/SingleDashboard/helper';
import CustomButton from 'components/shared/Buttons/CustomButton';
import CustomTextField from 'components/shared/Forms/CustomTextField';
import RHFCheckBox from 'components/shared/Forms/RHFCheckBox';
import RHFSelect from 'components/shared/Forms/RHFSelect';
import RHFTimeSelector from 'components/shared/Forms/RHFTimeSelector';
import theme from 'components/utils/theme';
import { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Button } from 'rsuite';
import { object } from 'yup';
import styles from "./devicePolicies.module.scss";
import { deviceUserRoles, formDevicePolicySchema, YupDevicePolicySchema } from './helper';
import { CREATE_DEVICE_POLICY } from '../../../../../redux/types/organization.types';
import dayjs from 'dayjs';

const DevicePolicyForm = ({ handleDevicePolicyClose, policy }) => {
  const dispatch = useDispatch();
  let loginSchema = object(YupDevicePolicySchema);
  const { control, handleSubmit, reset, setValue, getValues, formState: { errors, isValid } } = useForm(formDevicePolicySchema(loginSchema));

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'paths'
  });

  const [isTimeRestricted, setIsTimeRestricted] = useState(true);

  const handleClose = () => {
    handleDevicePolicyClose();
  }

  useEffect(() => {
    if (Object.keys(policy)?.length) {
      reset(policy)
      if (policy.startTime || policy.endTime) {
        setValue("startTime", dayjs(policy.startTime))
        setValue("endTime", dayjs(policy.endTime))
        setIsTimeRestricted(false)
      }
    }
  }, [policy, reset, setValue])

  const handleDevicePolicySubmit = (payload) => {
    payload.orgId = "xzzzo";
    dispatch({ type: CREATE_DEVICE_POLICY, payload });
    handleClose()
  };

  const addPath = () => {
    append({
      "path": "",
      "N": true,
      "RX": false,
      "R": false,
      "W": false,
      "D": false
    });
  };

  const handleTimeSwitch = () => {
    setIsTimeRestricted(prevState => !prevState);
  }


  return (
    <form onSubmit={handleSubmit(handleDevicePolicySubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CustomTextField
            control={control}
            errors={errors}
            name="policyName"
            type="text"
            label="Policy Name"
            borderRadius="5px"
            required
            placeHolder="Policy Name"
            size='small'
          />
        </Grid>
        <Grid item xs={6}>
          <RHFSelect
            control={control}
            name="os"
            label="Operating System"
            options={osOptions}
          />
        </Grid>
        <Grid item xs={12} >
          <div>
            <Accordion sx={{ background: theme.palette.background.paper, boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 1px rgba(0,0,0,0.12)" }} defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                File/Folder Access
                {console.log("getv", getValues())}
              </AccordionSummary>
              <AccordionDetails>
                {fields.map((item, index) => (
                  <Paper key={item.id} className={styles.pathPaper} variant="outlined">
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12}>
                        <CustomTextField
                          control={control}
                          errors={errors}
                          name={`paths[${index}].path`}
                          size="small"
                          type="text"
                          label="Enter Path to Restrict Access"
                          borderRadius="5px"
                          placeHolder="C:\Windows\System32\Config"
                        />
                      </Grid>
                      <Grid container px={3} justifyContent="space-between" alignItems="center" item xs={12}>
                        {/* "No Access" Checkbox */}
                        <RHFCheckBox
                          control={control}
                          errors={errors}
                          name={`paths[${index}].N`}
                          label="No Access"
                          onChange={(e) => {
                            const value = e.target.checked;
                            setValue(`paths[${index}].N`, value);
                            if (value) {
                              // If "No Access" is checked, uncheck all other checkboxes
                              setValue(`paths[${index}].RX`, false);
                              setValue(`paths[${index}].R`, false);
                              setValue(`paths[${index}].W`, false);
                              setValue(`paths[${index}].D`, false);
                            }
                          }}
                        />
                        {/* Other Checkboxes */}
                        <RHFCheckBox
                          control={control}
                          errors={errors}
                          name={`paths[${index}].RX`}
                          label="Read & Execute"
                          onChange={(e) => {
                            const value = e.target.checked;
                            setValue(`paths[${index}].RX`, value);
                            if (value) {
                              setValue(`paths[${index}].N`, false);
                            }
                          }}
                        />
                        <RHFCheckBox
                          control={control}
                          errors={errors}
                          name={`paths[${index}].R`}
                          label="Read"
                          onChange={(e) => {
                            const value = e.target.checked;
                            setValue(`paths[${index}].R`, value);
                            if (value) {
                              setValue(`paths[${index}].N`, false);
                            }
                          }}
                        />
                        <RHFCheckBox
                          control={control}
                          errors={errors}
                          name={`paths[${index}].W`}
                          label="Write"
                          onChange={(e) => {
                            const value = e.target.checked;
                            setValue(`paths[${index}].W`, value);
                            if (value) {
                              setValue(`paths[${index}].N`, false);
                            }
                          }}
                        />
                        <RHFCheckBox
                          control={control}
                          errors={errors}
                          name={`paths[${index}].D`}
                          label="Delete"
                          onChange={(e) => {
                            const value = e.target.checked;
                            setValue(`paths[${index}].D`, value);
                            if (value) {
                              setValue(`paths[${index}].N`, false);
                            }
                          }}
                        />
                      </Grid>
                      <Grid container justifyContent="flex-end">
                        <Button onClick={() => remove(index)}>Remove Path</Button>
                      </Grid>
                    </Grid>
                  </Paper>
                ))}

                <Grid container justifyContent="flex-end">
                  <Button startIcon={<Add />} onClick={addPath}>Add Another Path</Button>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                Time Based Access
              </AccordionSummary>
              <AccordionDetails>
                <Paper className={styles.pathPaper} variant="outlined">
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      {isTimeRestricted ? (
                        <>
                          <Typography variant='body1'>Restrict to login at specific time</Typography>
                          <Typography variant='subtitle2'>
                            Limit device users to logging in only at certain times
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography variant='body1'>Remove Time Restriction</Typography>
                          <Typography variant='subtitle2'>
                            You can disable the login time restrictions for device users.
                          </Typography>
                        </>
                      )}
                    </Grid>
                    <Grid item xs={4} display={'flex'} justifyContent={'flex-end'} >
                      <IOSSwitch checked={!isTimeRestricted} onClick={() => handleTimeSwitch()}
                      />
                    </Grid>
                    {!isTimeRestricted &&
                      <>
                        <Grid item xs={6}>
                          <RHFSelect
                            control={control}
                            name="startDate"
                            label="Start Day"
                            options={weekDays}
                            size='small'
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <RHFSelect
                            control={control}
                            name="endDate"
                            label="End Day"
                            options={weekDays}
                            size='small'
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <RHFTimeSelector
                            control={control}
                            name="startTime"
                            label="Start Time"
                            errors={errors}
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <RHFTimeSelector
                            control={control}
                            name="endTime"
                            label="End Time"
                            errors={errors}
                            required={true}
                          />
                        </Grid>
                      </>
                    }
                  </Grid>
                </Paper>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel3-content"
                id="panel3-header">
                Credentials
              </AccordionSummary>
              <AccordionDetails>
                <Paper className={styles.pathPaper} variant="outlined">
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                      <CustomTextField
                        control={control}
                        errors={errors}
                        name={"username"}
                        size="small"
                        type="text"
                        label="User Name"
                        borderRadius="5px"
                        placeHolder="Bdata"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomTextField
                        control={control}
                        errors={errors}
                        name={`password`}
                        size="small"
                        type="password"
                        label="Password"
                        borderRadius="5px"
                        placeHolder="******"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <RHFSelect
                        control={control}
                        name="role"
                        label="Role"
                        options={deviceUserRoles}
                        size='small'
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </AccordionDetails>
            </Accordion>
          </div>

        </Grid>
        {!Object.keys(policy)?.length ?
          <>
            <Grid item xs={6}></Grid>
            <Grid item xs={3}>
              <CustomButton
                padding="0 50px"
                color={theme.palette.primary.main}
                bgColor={theme.palette.background.default}
                hover={theme.palette.gray.light}
                label="Cancel"
                type="button"
                fullWidth
                onClick={handleClose}
              />
            </Grid>
            <Grid item xs={3}>
              <CustomButton
                padding="0 50px"
                color={theme.palette.background.default}
                bgColor={theme.palette.primary.main}
                hover={theme.palette.info.main}
                label="Add"
                type="submit"
                fullWidth
                disabled={!isValid}
              />
            </Grid>
          </>

          : null}
      </Grid>
      <br />
    </form>
  );
};

export default DevicePolicyForm;
